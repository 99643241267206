import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SheetsSection.css';
import simpleSheetsImage from '../assets/simple_sheets_2.svg';
import customMarketsImage from '../assets/custom_markets_sheet_2.svg';
import detailedResearchImage from '../assets/detailed_research_2.svg';
import Button from './ui/Button';

const SheetsSection = ({ isAuthenticated }) => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        if (isAuthenticated) {
            navigate('/account?tab=Dashboard');
        } else {
            navigate('/signup');
        }
    };

    return (
        <div className="sheets-container">
            <div className="sheets-section">
                <div className="image-box">
                    <div className="image-container">
                        <img src={simpleSheetsImage} alt="Simple Sheets" className="sheets-images" />
                        <div className="fade-overlay"></div>
                    </div>
                    <div className="text-container">
                        <h3>SnapSheets™</h3>
                        <p>Transform neighborhood data into compelling client presentations in minutes. Our hyper-local analytics help you highlight key market indicators and neighborhood characteristics that matter most to your buyers and sellers.</p>
                    </div>
                </div>
                <div className="image-box">
                    <div className="image-container">
                        <img src={customMarketsImage} alt="Custom Market Sheets" className="sheets-images" />
                        <div className="fade-overlay"></div>
                    </div>
                    <div className="text-container">
                        <h3>CustomCanvas™</h3>
                        <p>Stand out with professionally branded market reports. Customize layouts and data points to match your expertise and client needs, while maintaining your brokerage's visual identity. Perfect for listing presentations and buyer consultations.</p>
                    </div>
                </div>
                <div className="image-box">
                    <div className="image-container">
                        <img src={detailedResearchImage} alt="Detailed Research" className="sheets-images" />
                        <div className="fade-overlay"></div>
                    </div>
                    <div className="text-container">
                        <h3>DeepDive™</h3>
                        <p>Compare neighborhoods with precision using side-by-side analytics on demographics, market trends, and community features. Identify emerging opportunities and help clients make informed decisions with specific, data-driven insights.</p>
                    </div>
                </div>
            </div>
            <div className='bottom-spacing'>
                <Button 
                    variant="2"
                    onClick={handleButtonClick}
                >
                    {isAuthenticated ? "Go to Dashboard" : "Let me try!"}
                </Button>
            </div>

        </div>
    );
};

export default SheetsSection;