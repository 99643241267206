import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { verifyEmail, createBackendUser, loginUser, resendVerificationCode, get_current_user } from '../actions/auth';
import { signOut } from '@aws-amplify/auth';
import './VerificationPage.css';

const VerificationPage = () => {
    const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [resendTimer, setResendTimer] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const inputRefs = useRef([]);
    const timerRef = useRef(null);

    const email = location.state?.email;
    const password = location.state?.password;
    const userData = location.state?.userData;
    const fromDashboard = location.state?.fromDashboard;
    const fromLogin = location.state?.fromLogin;

    useEffect(() => {
        // Auto-focus the first input field when component mounts
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }

        // Cleanup timer on unmount
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, []);

    const startResendTimer = () => {
        setResendTimer(60);
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
        timerRef.current = setInterval(() => {
            setResendTimer(prev => {
                if (prev <= 1) {
                    clearInterval(timerRef.current);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    };

    useEffect(() => {
        const validateState = () => {
            if (!email) {
                console.error('No email provided to verification page');
                navigate('/login');
                return false;
            }

            if (!fromDashboard && !fromLogin && (!password || !userData)) {
                console.error('Missing required signup data');
                navigate('/signup');
                return false;
            }

            return true;
        };

        const sendVerificationCodeIfNeeded = async () => {
            if (!validateState()) return;

            // Send new verification code for dashboard or login cases
            if (fromDashboard || fromLogin) {
                try {
                    await resendVerificationCode(email);
                } catch (error) {
                    console.error('Error sending verification code:', error);
                    setError('Failed to send verification code. Please try again.');
                    if (fromLogin) {
                        navigate('/login');
                    } else {
                        navigate('/signup');
                    }
                }
            }
        };

        sendVerificationCodeIfNeeded();
    }, [email, password, userData, fromDashboard, fromLogin, navigate]);

    const handleVerification = async (e, code) => {
        if (e) e.preventDefault();
        const fullCode = code || verificationCode.join('');
        if (fullCode.length !== 6) return;

        setIsLoading(true);
        setError(null);

        try {
            // First verify the email
            await verifyEmail(email, fullCode);

            // For all flows, we need to ensure we have a session
            const loginResult = await loginUser(email, password);
            if (!loginResult.success || !loginResult.session) {
                throw new Error('Failed to sign in after verification');
            }

            // Try to get current backend user
            try {
                const backendUser = await get_current_user();

                // If we have a backend user, just handle redirects
                if (backendUser && backendUser.id) {
                    if (fromDashboard) {
                        navigate('/dashboard');
                    } else {
                        await signOut();
                        alert('Email verified successfully! Please login again.');
                        navigate('/login');
                    }
                    return;
                }
            } catch (error) {
                // No backend user exists we'll create one
                console.log('No backend user found, creating one...');
            }

            // If we get here, we need to create a backend user
            // For fromDashboard flow, create minimal backend user
            //check if missing needed data from state
            await createBackendUser(
                userData?.userId || loginResult.session.userSub, // fallback to email if no userData
                email || loginResult.tokens.signInDetails.loginId,
                userData?.firstName || 'New',
                userData?.lastName || 'User',
                loginResult.session
            );

            // Handle redirects after backend user creation
            if (fromDashboard) {
                alert('Account setup completed successfully!');
                navigate('/dashboard');
            } else {
                alert('Account created successfully! Please login to continue.');
                await signOut();
                navigate('/login');
            }
        } catch (error) {
            await signOut();
            console.error('Verification error:', error);
            let errorMessage = 'Verification failed. Please try again.';

            if (error.name === 'CodeMismatchException') {
                errorMessage = 'Invalid verification code. Click resend code and please try again.';
            } else if (error.name === 'ExpiredCodeException') {
                errorMessage = 'Verification code has expired. We\'ll send you a new one.';
                try {
                    await resendVerificationCode(email);
                    errorMessage += ' New code sent!';
                } catch (resendError) {
                    errorMessage += ' Failed to send new code.';
                }
            }

            setError(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    // Existing input handling code remains the same
    const handleChange = (index, value) => {
        if (value.length <= 1 && /^[0-9]*$/.test(value)) {
            const newCode = [...verificationCode];
            newCode[index] = value;
            setVerificationCode(newCode);

            if (value && index < 5) {
                inputRefs.current[index + 1].focus();
            } else if (index === 5) {
                handleVerification(null, newCode.join(''));
            }
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && !verificationCode[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text').slice(0, 6);
        if (/^\d{6}$/.test(pastedData)) {
            setVerificationCode(pastedData.split(''));
            handleVerification(null, pastedData);
        }
    };

    const handleResendCode = async () => {
        if (resendTimer > 0) return;

        setIsLoading(true);
        try {
            await resendVerificationCode(email);
            startResendTimer();
            alert('A new verification code has been sent to your email.');
            setError('');
        } catch (error) {
            console.error('Error resending code:', error);
            setError('Failed to send new verification code. Please try again.');
        } finally {
            setIsLoading(false);
            setVerificationCode(['', '', '', '', '', '']);
        }
    };

    return (
        <div className="verification-page">
            <div className="verification-page__container">
                <h2 className="verification-page__title">Verify Your Email</h2>
                <p className="verification-page__instruction">Please enter the 6-digit code sent to {email}</p>
                {error && <p className="verification-page__error">{error}</p>}
                <form onSubmit={(e) => handleVerification(e)} className="verification-page__form">
                    <div className="verification-page__inputs" onPaste={handlePaste}>
                        {verificationCode.map((digit, index) => (
                            <input
                                key={index}
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handleChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                ref={(el) => inputRefs.current[index] = el}
                                className="verification-page__input"
                                disabled={isLoading}
                                required
                            />
                        ))}
                    </div>
                    {isLoading && <p>Verifying...</p>}
                    <button
                        type="button"
                        onClick={handleResendCode}
                        className="verification-page__resend-button"
                        disabled={isLoading || resendTimer > 0}
                    >
                        {resendTimer > 0 ? `Resend Code (${resendTimer}s)` : 'Resend Code'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default VerificationPage;