import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <div className="realanalytica-notfound">
            <div className="realanalytica-notfound__container">
                <div className="realanalytica-notfound__content-wrapper">
                    <div className="realanalytica-notfound__text-block">
                        <h1 className="realanalytica-notfound__title">
                            404: Page Not Found
                        </h1>

                        <p className="realanalytica-notfound__subtitle">
                            The page you're looking for doesn't exist or has been moved.
                        </p>

                        <button
                            onClick={() => navigate('/')}
                            className="realanalytica-notfound__button"
                        >
                            Return to Home
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;