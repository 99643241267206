import React, { useMemo } from 'react';
import { useSpring, animated } from 'react-spring';

// Helper function to calculate total height for a group of boxes
const calculateGroupHeight = (boxCount) => {
  const baseHeight = 120;
  const boxHeight = 178;
  const boxPadding = 45;
  const interRowMargin = 55;
  
  const rows = Math.ceil(boxCount / 2);
  return baseHeight +
    (rows * boxHeight) +
    ((rows - 1) * boxPadding) +
    (rows > 1 ? (rows - 1) * interRowMargin : 0) +
    6;
};

const SubtypeOutline = React.memo(({ boxCount, isFirstSubtype, isFirstPage }) => {
  const singleBoxWidth = 46;
  const doubleBoxWidth = 92;
  const subtypeMargin = isFirstPage
    ? (isFirstSubtype ? 85 : 10)
    : (isFirstSubtype ? 60 : 10);

  const height = calculateGroupHeight(boxCount);
  const width = boxCount === 1 ? singleBoxWidth : doubleBoxWidth;

  const animation = useSpring({
    width: `${width}%`,
    height: `${height}px`,
    config: { tension: 100, friction: 20 },
  });

  return (
    <animated.div
      className="subtype-outline"
      style={{
        ...animation,
        marginTop: `${subtypeMargin}px`,
        marginLeft: '4%',
        position: 'relative',
        border: '1px solid black',
      }}
    />
  );
});

const SubtypeOverlay = React.memo(({ subtypes, contentBoxes, pageIndex }) => {
  // Group boxes by subtype, regardless of location
  const boxesBySubtype = useMemo(() => {
    return contentBoxes.reduce((acc, box) => {
      if (!acc[box.subtype]) {
        acc[box.subtype] = [];
      }
      acc[box.subtype].push(box);
      return acc;
    }, {});
  }, [contentBoxes]);

  // Get subtypes that have boxes on this page
  const subtypesOnThisPage = useMemo(() => {
    return subtypes.filter(subtype => 
      boxesBySubtype[subtype] && boxesBySubtype[subtype].length > 0
    );
  }, [subtypes, boxesBySubtype]);

  const isFirstPage = pageIndex === 0;

  // Calculate total rows for validation
  const totalRows = useMemo(() => {
    return subtypesOnThisPage.reduce((total, subtype) => {
      const boxCount = boxesBySubtype[subtype].length;
      return total + Math.ceil(boxCount / 2);
    }, 0);
  }, [subtypesOnThisPage, boxesBySubtype]);

  // Validate that we don't exceed 3 rows per page
  if (totalRows > 3) {
    console.warn(`Page ${pageIndex} exceeds maximum row limit: ${totalRows} rows`);
  }

  return (
    <div className="subtype-overlay">
      {subtypesOnThisPage.map((subtype, index) => {
        const subtypeBoxes = boxesBySubtype[subtype] || [];
        const totalBoxCount = subtypeBoxes.length;

        return (
          <SubtypeOutline
            key={subtype}
            boxCount={totalBoxCount}
            isFirstSubtype={index === 0}
            isFirstPage={isFirstPage}
          />
        );
      })}
    </div>
  );
});

export default SubtypeOverlay;