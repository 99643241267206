import React, { useState, useRef, useEffect, useCallback, memo, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import blueArrowIcon from "../assets/blueArrow.png"
import algoIcon from "../assets/algo icon-01 1.svg";
import recentHomeSalesIcon from "../assets/home-general-icon.svg";
import schoolIcon from "../assets/schools-icon.svg";
import populationIcon from "../assets/community-icon.svg";
import crimeIcon from "../assets/crime-icon-01 1.svg";
import incomeIcon from "../assets/dollarsign-icon.svg";
import dynamicNeighborhoodComparisonIcon from "../assets/home-general-icon.svg";
import infoIcon from '../assets/infoIcon.svg';
import locationMetricsIcon from "../assets/locationMetricsIcon.svg"
import { createPortal } from 'react-dom';

export const categories = [
  {
    name: "Algorithm",
    icon: algoIcon,
    items: [
      {
        id: "valuation-index",
        name: "Valuation Index",
        component: "ValuationTab",
        info: "Historical property valuation trends with interactive time period selection"
      }
    ],
    info: "Historical valuation analysis and trends.",
  },
  {
    name: "Recent Home Sales",
    icon: recentHomeSalesIcon,
    items: [
      {
        id: "recent-sales-summary",
        name: "Recent Sales Summary",
        component: "RecentHomeSalesSummary",
        info: "Summary statistics of recent home sales including average price, volume, and property characteristics"
      },
      {
        id: "recent-sales-list",
        name: "Latest Sales",
        component: "RecentHomeSalesList",
        info: "Detailed list of the most recent home sales with property details and sale prices"
      }
    ],
    info: "Latest data on home sales in the area.",
  },
  {
    name: "Location Metrics",
    icon: locationMetricsIcon,
    items: [
      {
        id: "locmet-quickstats",
        name: "Location Summary",
        component: "LocMetQuickStats",
        info: "Essential location metrics including median rent, home value, and build year"
      },
      {
        id: "locmet-hsngvalue",
        name: "Household Valuations",
        component: "LocMetHsngValue",
        info: "Distribution of home values across different price ranges"
      },
      {
        id: "locmet-hsngbltyr",
        name: "Housing Built Years",
        component: "LocMetHsngBltYr",
        info: "Distribution of housing stock by construction year"
      }
    ],
    info: "Location and housing statistics specific to the area"
  },
  {
    name: "Population",
    icon: populationIcon,
    items: [
      {
        id: "popstat-quickstats",
        name: "Quick Population Statistics",
        component: "PopStatQuickStats",
        info: "Key population metrics including household size, median age, and housing units"
      },
      {
        id: "popstat-popagg",
        name: "Population Growth Trends",
        component: "PopStatPopAgg",
        info: "Historical population growth data and trends"
      },
      {
        id: "popstat-hsldagg",
        name: "Household Trends",
        component: "PopStatHsldAgg",
        info: "Historical household data with 5-year projections"
      },
      {
        id: "popstat-racialdem",
        name: "Racial Demographics",
        component: "PopStatRacialDem",
        info: "Breakdown of population by racial demographics"
      },
      {
        id: "popstat-agedem",
        name: "Age Demographics",
        component: "PopStatAgeDem",
        info: "Population distribution across age groups"
      },
      {
        id: "popstat-transport",
        name: "Transportation Methods",
        component: "PopStatTransport",
        info: "Community transportation preferences and patterns"
      },
      {
        id: "popstat-hsngocpd",
        name: "Housing Occupancy",
        component: "PopStatHsngOcpd",
        info: "Housing occupancy and vacancy rates"
      },
      {
        id: "popstat-educpct",
        name: "Education Statistics",
        component: "PopStatEducPct",
        info: "Educational attainment distribution"
      }
    ],
    info: "Comprehensive demographic and population statistics"
  },
  {
    name: "School",
    icon: schoolIcon,
    items: [
      {
        id: "school-enrlmtlevel",
        name: "Enrollment by Grade Level",
        component: "SchoolEnrlmtLevelPcts",
        info: "Distribution of student enrollment across different grade levels"
      },
      {
        id: "school-enrlmttype",
        name: "Public vs Private Enrollment",
        component: "SchoolEnrlmtTypePcts",
        info: "Breakdown of enrollment between public and private institutions"
      }
    ],
    info: "Educational statistics and enrollment data for the area"
  },
  {
    name: "Crime",
    icon: crimeIcon,
    items: [
      {
        id: "crime-violent",
        name: "Violent Crime Analysis",
        component: "ViolentCrimeBar",
        info: "Analysis of violent crime statistics including rankings and trends"
      },
      {
        id: "crime-property",
        name: "Property Crime Analysis",
        component: "PropertyCrimeBar",
        info: "Analysis of property crime statistics including rankings and trends"
      }
    ],
    info: "Comprehensive crime statistics and safety analysis"
  },
  {
    name: "Income",
    icon: incomeIcon,
    items: [
      {
        id: "income-quickstats",
        name: "Quick Income Statistics",
        component: "IncomeQuickStats",
        info: "Essential income metrics including average commute time, median household income, and per capita income"
      },
      {
        id: "income-hsldpct",
        name: "Income Distribution",
        component: "IncomeHsldPct",
        info: "Detailed breakdown of household income distribution across different income brackets"
      }
    ],
    info: "Comprehensive income and cost of living statistics"
  },
  {
    name: "Dynamic Comparison",
    icon: dynamicNeighborhoodComparisonIcon,
    items: [
      {
        id: "dynamic-population",
        name: "Population Comparison",
        component: "TopGrowthComparison",
        info: "Compare population metrics with similar neighborhoods"
      },
      {
        id: "dynamic-income",
        name: "Income Comparison",
        component: "MedianIncomeComparison",
        info: "Compare income levels with similar neighborhoods"
      }
    ],
    info: "Compare key metrics with similar neighborhoods"
  }
];

const DataCategoriesSection = ({ onSubmit, initialSelectedCategories = [], isExpanded, setIsExpanded, selectedLocations }) => {
  const [selectedCategories, setSelectedCategories] = useState(initialSelectedCategories);
  const [initialized, setInitialized] = useState(false);
  const [expandedCategory, setExpandedCategory] = useState(null);

  // Calculate max allowed categories based on number of selected locations.
  const maxCategories = selectedLocations.filter(Boolean).length === 2 ? 4 : 8;

  // Update selection messaging.
  const getSelectionMessage = useCallback(() => {
    if (selectedLocations.filter(Boolean).length === 2) {
      return `Pick up to four data visualizations (each will show for both locations)`;
    }
    return "Pick up to eight data visualizations";
  }, [selectedLocations]);

  useEffect(() => {
    if (!initialized) {
      setSelectedCategories(initialSelectedCategories);
      setInitialized(true);
    }
  }, [initialSelectedCategories, initialized]);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleCategory = useCallback((categoryItem) => {
    setSelectedCategories(prev => {
      const newCategories = prev.includes(categoryItem)
        ? prev.filter((c) => c !== categoryItem)
        : prev.length < maxCategories
          ? [...prev, categoryItem]
          : prev;

      onSubmit(newCategories);
      return newCategories;
    });
  }, [maxCategories, onSubmit]);

  const removeCategory = (categoryItem) => {
    const newCategories = selectedCategories.filter((c) => c !== categoryItem);
    setSelectedCategories(newCategories);
    onSubmit(newCategories);
  };

  const clearSelections = () => {
    setSelectedCategories([]);
    onSubmit([]);
  };

  const toggleShowMore = useCallback((categoryName, e) => {
    e.stopPropagation();
    setExpandedCategory(prev => prev === categoryName ? null : categoryName);
  }, []);

  const CategoryItems = memo(({ category, maxCategories, selectedCategories, toggleCategory, isExpanded }) => {
    // Show all items if expanded, otherwise only show first 2
    const visibleItems = isExpanded ? category.items : category.items.slice(0, 2);

    return (
      <div className={`auto-market-category-items-container ${isExpanded ? 'expanded' : ''}`}>
        <div className="auto-market-category-items-wrapper">
          {visibleItems.map((item) => (
            <div
              key={item.id}
              className={`data-category-item ${selectedCategories.includes(item) ? "selected" : ""
                } ${selectedCategories.length === maxCategories &&
                  !selectedCategories.includes(item)
                  ? "disabled"
                  : ""
                }`}
              onClick={() => toggleCategory(item)}
            >
              <span className="data-category-item-name">{item.name}</span>
              <InfoIcon info={item.info} />
            </div>
          ))}
        </div>
      </div>
    );
  });

  const InfoIcon = memo(({ info }) => {
    const [showInfo, setShowInfo] = useState(false);
    const [bubblePosition, setBubblePosition] = useState({ top: 0, left: 0 });
    const containerRef = useRef(null);
    const bubbleRef = useRef(null);

    const updatePosition = useCallback(() => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        setBubblePosition({
          top: rect.bottom + scrollTop,
          left: rect.left + (rect.width / 2) + scrollLeft
        });
      }
    }, []);

    useEffect(() => {
      if (showInfo) {
        updatePosition();
        window.addEventListener('scroll', updatePosition);
        window.addEventListener('resize', updatePosition);
      }

      return () => {
        window.removeEventListener('scroll', updatePosition);
        window.removeEventListener('resize', updatePosition);
      };
    }, [showInfo, updatePosition]);

    const handleMouseEnter = () => {
      console.log('Mouse enter triggered');
      setShowInfo(true);
    };

    const handleMouseLeave = (e) => {
      console.log('Mouse leave triggered', {
        relatedTarget: e.relatedTarget,
        bubbleContains: bubbleRef.current?.contains(e.relatedTarget)
      });

      if (bubbleRef.current && !bubbleRef.current.contains(e.relatedTarget)) {
        setShowInfo(false);
      }
    };

    return (
      <>
        <div
          ref={containerRef}
          className="info-icon-container"
          style={{
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center'
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={infoIcon}
            alt="Info"
            className="info-icon"
            style={{
              width: '10px',
              height: '10px',
              cursor: 'pointer'
            }}
          />
        </div>
        {showInfo && createPortal(
          <div
            ref={bubbleRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
              position: 'absolute',
              top: bubblePosition.top + 5,
              left: bubblePosition.left,
              transform: 'translateX(-50%)',
              backgroundColor: '#ECECEC',
              color: '#333',
              padding: '10px',
              borderRadius: '4px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              width: '200px',
              maxWidth: '90vw',
              zIndex: 9999,
              cursor: 'default'
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '-6px',
                left: '50%',
                transform: 'translateX(-50%) rotate(45deg)',
                width: '12px',
                height: '12px',
                backgroundColor: '#ECECEC',
                borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
                borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                borderRight: 'none',
                borderBottom: 'none'
              }}
            />
            <div
              style={{
                position: 'relative',
                zIndex: 1,
                fontSize: '12px',
                lineHeight: '1.4'
              }}
            >
              {info}
            </div>
          </div>,
          document.body
        )}
      </>
    );
  });
  const Portal = ({ children }) => {
    const [mounted, setMounted] = useState(false);
    const el = useMemo(() => document.createElement('div'), []);

    useEffect(() => {
      setMounted(true);
      document.body.appendChild(el);
      return () => document.body.removeChild(el);
    }, [el]);

    if (!mounted) {
      return null;
    }

    return createPortal(children, el);
  };

  // Helper function to calculate popover position
  const getPopoverPosition = (element) => {
    if (!element) return {};

    const rect = element.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    // Calculate base position
    let top = rect.bottom + scrollTop;
    let left = rect.left + (rect.width / 2) + scrollLeft;

    // Adjust for window edges
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const popoverWidth = 200; // width of the popover
    const popoverHeight = 100; // approximate height of the popover

    // Adjust horizontal position if needed
    if (left + (popoverWidth / 2) > windowWidth) {
      left = windowWidth - popoverWidth - 10;
    } else if (left - (popoverWidth / 2) < 0) {
      left = 10;
    } else {
      left -= popoverWidth / 2;
    }

    // Adjust vertical position if needed
    if (top + popoverHeight > windowHeight + scrollTop) {
      top = rect.top + scrollTop - popoverHeight - 10;
    }

    return { top: `${top}px`, left: `${left}px` };
  };


  const CategorySection = memo(({ category, maxCategories, selectedCategories, toggleCategory, expandedCategory, toggleShowMore }) => {
    const isExpanded = expandedCategory === category.name;

    return (
      <div className={`data-category ${isExpanded ? 'expanded' : ''}`}>
        <div className="data-category-header">
          <div className="data-category-title">
            <img
              src={category.icon}
              alt={category.name}
              className="category-icon"
            />
            <span className="category-name">{category.name}</span>
            <InfoIcon info={category.info} />
          </div>
        </div>
        <CategoryItems
          category={category}
          maxCategories={maxCategories}
          selectedCategories={selectedCategories}
          toggleCategory={toggleCategory}
          isExpanded={isExpanded}
        />
        {category.items.length > 2 && (
          <button
            className="category-expand-button"
            onClick={(e) => toggleShowMore(category.name, e)}
          >
            {isExpanded ? "Show Less" : "Show More"}
            <svg
              className={`data-category-expand-arrow ${isExpanded ? 'expanded' : ''}`}
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
      </div>
    );
  });

  return (
    <div className="data-categories-section">
      <div className="data-options-toggle" onClick={() => setIsExpanded(!isExpanded)}>
        Choose Data Options
        <img
          src={blueArrowIcon}
          alt="Expand"
          className={isExpanded ? "data-options-arrow rotated" : "data-options-arrow"}
        />
      </div>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            className="data-categories-content"
            initial={{ height: 0, opacity: 0 }}
            animate={{
              height: "auto",
              opacity: 1,
              transition: {
                height: { duration: 0.3, ease: "easeOut" },
                opacity: { duration: 0.2, delay: 0.1 }
              }
            }}
            exit={{
              height: 0,
              opacity: 0,
              transition: {
                height: { duration: 0.3, ease: "easeIn" },
                opacity: { duration: 0.2 }
              }
            }}
          >
            <motion.div
              initial={{ y: -10 }}
              animate={{ y: 0 }}
              exit={{ y: -10 }}
              className="data-categories-list"
            >
              <p className="data-categories-instruction">
                {getSelectionMessage()}
                {selectedCategories.length === maxCategories && (
                  <>
                    <br />
                    Click the blue arrow in the selections box to continue
                  </>
                )}
              </p>
              {categories.map((category) => (
                <CategorySection
                  key={category.name}
                  category={category}
                  maxCategories={maxCategories}
                  selectedCategories={selectedCategories}
                  toggleCategory={toggleCategory}
                  expandedCategory={expandedCategory}
                  toggleShowMore={toggleShowMore}
                />
              ))}
            </motion.div>
            <motion.div
              initial={{ y: -10 }}
              animate={{ y: 0 }}
              exit={{ y: -10 }}
              className="data-categories-selected"
            >
              <div className="data-categories-selected-list">
                {selectedCategories.map((item) => (
                  <motion.div
                    key={item.id}
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.9 }}
                    className="data-category-selected-item"
                  >
                    {item.name}
                    <button
                      onClick={() => toggleCategory(item)}
                      className="data-category-remove-item"
                      aria-label="Remove item"
                    />
                  </motion.div>
                ))}
              </div>
              {selectedCategories.length > 0 && (
                <motion.button
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={() => {
                    setSelectedCategories([]);
                    onSubmit([]);
                  }}
                  className="data-categories-clear"
                >
                  Clear Selections
                </motion.button>
              )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
export default memo(DataCategoriesSection);