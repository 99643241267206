import React, { useState, useEffect } from "react";
import "./Login.css";
import axios from "axios";
import { signIn, getCurrentUser, signInWithRedirect } from "@aws-amplify/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import googleLogo from "../assets/google logo 2.svg";
import logoImage from "../assets/SVG-realanalytica-tertiary-logo-blue.svg";
import loginBackgroundImage from "../assets/sign-up-page-building.svg";
import LoadingSpinner from "../components/LoadingSpinner"; // Import the LoadingSpinner component
import Button from '../components/ui/Button';
import { handleGoogleSignIn } from '../actions/auth';

const Login = ({ setIsAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const { email, password } = formData;

  const location = useLocation();
  const navigate = useNavigate();

  const checkAuthStatus = async () => {
    try {
      await getCurrentUser();
      setIsAuthenticated(true);
    } catch {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    checkAuthStatus();
    const searchParams = new URLSearchParams(location.search);
    const signupSuccess = searchParams.get("signup");

    if (signupSuccess === "success") {
      alert(
        "Signup successful! Please check your email for a verification code link."
      );
    }
  }, [location]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    try {
      const signInResponse = await signIn({
        username: email,
        password,
      });

      console.log("res", signInResponse);

      // Handle different sign-in scenarios
      if (signInResponse.nextStep?.signInStep === "CONFIRM_SIGN_UP") {
        // User needs to verify their email
        navigate("/verify", {
          state: {
            email,
            password,
            fromLogin: true,
            userData: {
              // If you have access to these from the sign-in response
              userId: signInResponse.userId || null,
              firstName: signInResponse.firstName || "",
              lastName: signInResponse.lastName || "",
            },
          },
        });
        return;
      }

      if (!signInResponse.isSignedIn) {
        throw new Error("Sign in failed. Please try again.");
      }

      // Successful login
      console.log("User signed in successfully", signInResponse);
      setIsAuthenticated(true);
      navigate("/account?tab=Dashboard");
    } catch (error) {
      console.error("Error signing in:", error);
      alert(error.message);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const continueWithGoogle = async () => {
    setIsLoading(true);
    try {
      await handleGoogleSignIn(); // Use our new helper function
    } catch (error) {
      console.error('Error initiating Google sign-in:', error);
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="login-page">
      <img
        src={loginBackgroundImage}
        alt="Login Background"
        className="login-background-image"
      />
      <div className="login-card">
        <form onSubmit={onSubmit}>
          <img src={logoImage} alt="Logo" className="login-logo" />
          <div className="input-group">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => onChange(e)}
              required
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => onChange(e)}
              required
            />
          </div>
          <Button type="submit" variant="2" size="l" className="login-button" style={{ width: '100%', margin: 0 }}>
            Log In
          </Button>
        </form>
        <div className="register-footer">
          <span>Don't have an account? - </span>
          <Link to="/signup">Register</Link>
        </div>
        <div className="forgot-password-footer">
          <Link to="/reset-password">Forgot your password?</Link>
        </div>
        <div className="divider-container">
          <div className="divider-line"></div>
          <span className="divider-text">or</span>
          <div className="divider-line"></div>
        </div>
        <Button
          type="button"
          variant="2"
          size="l"
          onClick={continueWithGoogle}
          className="social-login-button"
          style={{ width: '100%', margin: 0 }}  // Ensure full width at all screen sizes
        >
          <div className="social-login-content">
            <img
              src={googleLogo}
              alt="Google"
              className="social-login-icon"
            />
            <span>Continue with Google</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default Login;
