import React, { useState, useEffect, useCallback } from "react";
import posthog from 'posthog-js';
import "./HeroSection.css";
import { FaSearch } from "react-icons/fa";
import mapboxgl from "mapbox-gl";
import { ValuationGraph } from "./WatchlistComponent";
import { fetchPreviewData } from "../actions/auth";
import RankingsDisplay from "./RankingsDisplay";
import { useNavigate } from "react-router-dom";
import { Pie, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend
);

const trackEvent = (eventName, properties = {}) => {
  try {
    posthog.capture(eventName, {
      section: 'hero',
      timestamp: new Date().toISOString(),
      ...properties
    });
  } catch (error) {
    console.error('Error tracking event:', error);
  }
};

const SignupButton = ({ isAuthenticated, navigateUser }) => {
  const handleClick = () => {
    trackEvent('hero_signup_clicked', {
      is_authenticated: isAuthenticated,
      destination: isAuthenticated ? 'dashboard' : 'signup'
    });
    navigateUser();
  };

  return (
    <div className="realanalytica-hero__signup-overlay">
      <button
        className="realanalytica-hero__signup-button"
        onClick={handleClick}
      >
        {isAuthenticated ? "View more on dashboard" : "Sign Up To View More"}
      </button>
    </div>
  );
};

const PieChartDisplay = () => {
  const data = {
    labels: ["Owned", "Rented", "Vacant"],
    datasets: [
      {
        data: [65, 30, 5],
        backgroundColor: ["royalblue", "#4CAF50", "#FFC107"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          font: { size: 12 },
        },
      },
    },
  };

  return (
    <div className="realanalytica-hero__blurred-container">
      <h4 className="realanalytica-hero__chart-title">Housing Occupancy</h4>
      <div className="realanalytica-hero__chart-wrapper">
        <Pie data={data} options={options} />
      </div>
    </div>
  );
};

const BarChartDisplay = () => {
  const data = {
    labels: ["2020", "2021", "2022", "2023", "2024"],
    datasets: [
      {
        label: "Population Growth",
        data: [12000, 12500, 13200, 13800, 14500],
        backgroundColor: "#1352E2",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: false,
        ticks: {
          font: { size: 10 },
        },
      },
      x: {
        ticks: {
          font: { size: 10 },
        },
      },
    },
  };

  return (
    <div className="realanalytica-hero__blurred-container realanalytica-hero__blurred-container--desktop-only">
      <h4 className="realanalytica-hero__chart-title">Population Trends</h4>
      <div className="realanalytica-hero__chart-wrapper">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_TOKEN;

const sampleLocationData = {
  indexData: {
    "2/1/24": 101400,
    "3/1/24": 102503,
    "4/1/24": 102305,
    "5/1/24": 101379,
    "6/1/24": 102922,
    "7/1/24": 104031,
    "8/1/24": 104182,
    "9/1/24": 102111,
    "10/1/24": 103346,
    "11/1/24": 104723,
    "12/1/24": 103341,
    "1/1/25": 102325,
  },
  rankingsData: {
    totalNeighborhoods: 16,
    crime: 7,
    schools: 2,
    income: 3,
    appreciation: 15,
    density: 8,
    diversity: 14,
    affordability: 12,
    trafficAccess: 16,
    greenSpace: 9,
  },
};

const HeroSection = ({ isAuthenticated }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [layoutShifted, setLayoutShifted] = useState(false);
  const [showLoadingPlaceholder, setShowLoadingPlaceholder] = useState(false);
  const [showData, setShowData] = useState(false);
  const navigate = useNavigate();
  const [previewData, setPreviewData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentLocationData, setCurrentLocationData] = useState(null);
  const [nextLocationData, setNextLocationData] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!e.target.closest(".realanalytica-hero__search-wrapper")) {
        setShowResults(false);
        setIsSearchFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const searchAddress = async (query) => {
    if (!query.trim() || query.length < 3 || !isSearchFocused) {
      setSearchResults([]);
      setShowResults(false);
      return;
    }

    try {
      const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        query
      )}.json`;
      const params = new URLSearchParams({
        access_token: mapboxgl.accessToken,
        country: "US",
        proximity: "-71.4128,41.8240",
        bbox: "-71.888473,41.146339,-71.120094,42.018798",
        limit: 5,
      });

      const response = await fetch(`${endpoint}?${params}`);

      if (!response.ok) {
        trackEvent('hero_search_error', {
          error_status: response.status,
          query: query
        });

        console.error("Search failed:", response.status);
        return;
      }

      const data = await response.json();
      const riResults = data.features.filter((feature) => {
        const hasRiContext = feature.context?.some(
          (ctx) => ctx.text === "Rhode Island" || ctx.short_code === "US-RI"
        );
        return hasRiContext;
      });

      trackEvent('hero_search_completed', {
        query: query,
        results_count: riResults.length,
      });

      setSearchResults(riResults);
      setShowResults(riResults.length > 0 && isSearchFocused);
    } catch (error) {
      trackEvent('hero_search_error', {
        error_message: error.message,
        query: query
      });
      console.error("Error searching address:", error);
      setSearchResults([]);
      setShowResults(false);
    }
  };

  useEffect(() => {
    if (searchQuery.length >= 3) {
      const timeoutId = setTimeout(() => {
        searchAddress(searchQuery);
      }, 350);
      return () => clearTimeout(timeoutId);
    } else {
      setSearchResults([]);
      setShowResults(false);
    }
  }, [searchQuery]);

  const extractLocation = (feature) => {
    if (!feature.context) return null;

    const neighborhood = feature.context.find((ctx) =>
      ctx.id.startsWith("neighborhood")
    );
    if (neighborhood) {
      return {
        name: neighborhood.text,
        type: "neighborhood",
        city:
          feature.context.find((ctx) => ctx.id.startsWith("place"))?.text ||
          null,
        state: "Rhode Island",
        fullAddress: feature.place_name,
        coordinates: feature.geometry.coordinates,
      };
    }

    const city = feature.context.find((ctx) => ctx.id.startsWith("place"));
    if (city) {
      return {
        name: city.text,
        type: "city",
        city: city.text,
        state: "Rhode Island",
        fullAddress: feature.place_name,
        coordinates: feature.geometry.coordinates,
      };
    }

    return {
      name: "Rhode Island",
      type: "state",
      city: null,
      state: "Rhode Island",
      fullAddress: feature.place_name,
      coordinates: feature.geometry.coordinates,
    };
  };

  const handleResultClick = async (result) => {

    setSearchQuery(result.place_name);
    const locationInfo = extractLocation(result);
    trackEvent('hero_location_selected', {
      location_name: locationInfo.name,
      location_type: locationInfo.type,
      full_address: locationInfo.fullAddress,
      coordinates: locationInfo.coordinates
    });

    // Keep current data visible during transition
    setIsTransitioning(true);

    // Start animation sequence
    setTimeout(() => {
      setLayoutShifted(true);
      setIsAnimating(true);
      setSelectedLocation(locationInfo);

      if (!currentLocationData) {
        setShowData(true);
      }
    }, 10);

    setError(null);
    setIsLoading(true);
    setShowResults(false);
    setIsSearchFocused(false);

    try {
      const data = await fetchPreviewData(locationInfo.name, locationInfo.type);

      trackEvent('hero_preview_data_loaded', {
        location_name: locationInfo.name,
        location_type: locationInfo.type,
        has_index_data: !!data.location.indexData,
        has_rankings: !!data.location.rankings
      });

      const newData = {
        location: data.location.location,
        indexData: data.location.indexData,
        rankingsData: data.location.rankings[0],
      };

      // Set next location data
      setNextLocationData(newData);

      // After a brief delay, swap the data and reset transition state
      setTimeout(() => {
        setCurrentLocationData(newData);
        setNextLocationData(null);
        setIsTransitioning(false);
        setPreviewData(newData);
      }, 600); // Adjust timing to match your transition duration
    } catch (err) {
      trackEvent('hero_preview_data_error', {
        location_name: locationInfo.name,
        location_type: locationInfo.type,
        error_message: err.message
      });
      console.error("Error fetching preview data:", err);
      setError("Failed to load location data");
      setIsTransitioning(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchFocus = () => {
    trackEvent('hero_search_focused');
    setIsSearchFocused(true);
  };

  // Track search blur (potential abandonment)
  const handleSearchBlur = (e) => {
    if (!e.relatedTarget?.closest(".realanalytica-hero__search-results")) {
      setIsSearchFocused(false);
      if (searchQuery && !selectedLocation) {
        trackEvent('hero_search_abandoned', {
          partial_query: searchQuery,
          had_results: searchResults.length > 0
        });
      }
    }
  };

  const navigateUser = () => {
    if (isAuthenticated) {
      navigate("/account?tab=Dashboard");
    } else {
      navigate("/signup");
    }
  };

  const getTitleClass = () => {
    return `realanalytica-hero__title ${isAnimating ? "text-shift-up" : ""
      }`.trim();
  };

  const getSubtitleClass = () => {
    return `realanalytica-hero__subtitle ${isAnimating ? "text-shift-up" : ""
      }`.trim();
  };

  const getSearchWrapperClass = () => {
    return `realanalytica-hero__search-wrapper ${isAnimating ? "search-shift-up" : ""
      }`.trim();
  };

  const getLocationDisplayClass = () => {
    return `realanalytica-hero__location-display ${isAnimating ? "fade-in" : ""
      }`.trim();
  };

  const getDataDisplaysClass = () => {
    return `realanalytica-hero__data-displays ${showData ? "fade-in" : ""
      }`.trim();
  };

  const getContentWrapperClass = () => {
    return `realanalytica-hero__content-wrapper ${layoutShifted ? "shifted" : ""
      }`.trim();
  };

  const getDataContainerClass = () => {
    return `realanalytica-hero__data-container ${layoutShifted ? "expanded" : ""
      }`.trim();
  };
  const renderLocationData = (data, isNext = false) => (
    <div
      className={`realanalytica-hero__data-content ${isNext ? "next" : "current"
        } ${isTransitioning ? "transitioning" : ""}`}
    >
      <div className="realanalytica-hero__selected-data">
        {error ? (
          <div className="error-message">{error}</div>
        ) : (
          <ValuationGraph
            locationData={data || sampleLocationData}
            color="#1352E2"
          />
        )}
      </div>
      <RankingsDisplay
        rankingsData={data?.rankingsData}
        isLoading={isLoading}
        error={error}
      />
    </div>
  );

  return (
    <div className="realanalytica-hero">
      <div className="realanalytica-hero__container">
        <div className={getContentWrapperClass()}>
          <div className="realanalytica-hero__text-block">
            <h1 className={getTitleClass()}>
              Market Analytics That Engage Buyers.
            </h1>

            <p className={getSubtitleClass()}>
              Create professional, data driven market sheets and research
              localities that help you sell more.
            </p>

            <div className={getSearchWrapperClass()}>
              <div className="realanalytica-hero__search-bar">
                <FaSearch className="realanalytica-hero__search-icon" />
                <input
                  type="text"
                  placeholder="Search for your listing within Rhode Island"
                  className="realanalytica-hero__search-input"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onFocus={handleSearchFocus}
                  onBlur={handleSearchBlur}
                />
                {showResults && searchResults.length > 0 && (
                  <div className="realanalytica-hero__search-results">
                    <ul>
                      {searchResults.map((result) => (
                        <li
                          key={result.id}
                          onClick={() => handleResultClick(result)}
                        >
                          {result.place_name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className={getDataContainerClass()}>
              {selectedLocation && (
                <div className={getDataContainerClass()}>
                  {selectedLocation && (
                    <div className={getLocationDisplayClass()}>
                      <h2 className="realanalytica-hero__location-name">
                        {previewData?.location?.name
                          ? `${previewData.location.geoType
                            .charAt(0)
                            .toUpperCase() +
                          previewData.location.geoType
                            .slice(1)
                            .toLowerCase()
                          }: ${previewData.location.name.split(",")[0]}`
                          : selectedLocation.fullAddress}
                      </h2>
                    </div>
                  )}

                  <div className={getDataDisplaysClass()}>
                    <div
                      className={`realanalytica-hero__selected-data ${!isLoading ? "visible" : ""
                        }`}
                    >
                      {error ? (
                        <div className="herosection-error-message">{error}</div>
                      ) : (
                        <ValuationGraph
                          locationData={previewData || sampleLocationData}
                          color="#1352E2"
                          customStyles={{ heroStyle: true }}  // Add this line
                        />
                      )}
                    </div>
                    <RankingsDisplay
                      rankingsData={previewData?.rankingsData}
                      isLoading={isLoading}
                      error={error}
                    />
                    <div className="realanalytica-hero__blurred-section">
                      <PieChartDisplay />
                      <BarChartDisplay />
                      <SignupButton
                        isAuthenticated={isAuthenticated}
                        navigateUser={navigateUser}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
