import React, { useState } from 'react';
import './DataTypeSelector.css';
import downArrow from '../assets/down-arrow-RA.svg';

const dataTypes = [
    {
        type: "Algorithm",
        subtitle: "Valuation Index"
    },
    {
        type: "Recent Home Sales",
        subtitle: "Recent Sales Summary | Latest Sales"
    },
    {
        type: "Location Metrics",
        subtitle: "Location Summary | Household Valuations | Housing Built Years"
    },
    {
        type: "Population",
        subtitle: "Quick Population Statistics | Population Growth Trends | Household Trends..."
    },
    {
        type: "School",
        subtitle: "Enrollment by Grade Level | Public vs Private Enrollment"
    },
    {
        type: "Crime",
        subtitle: "Violent Crime Analysis | Property Crime Analysis"
    },
    {
        type: "Income",
        subtitle: "Quick Income Statistics | Income Distribution"
    },
    {
        type: "Dynamic Comparison",
        subtitle: "Population Comparison | Income Comparison"
    }
];

const DataTypeSelector = ({ selectedType, onTypeSelect, isExpanded = false }) => {
    const [hoveredType, setHoveredType] = useState(null);

    const handleTypeClick = (type) => {
        if (onTypeSelect && typeof onTypeSelect === 'function') {
            onTypeSelect(type);
        }
    };

    return (
        <div className="data-type-selector">
            {dataTypes.map((item) => {
                const isHovered = hoveredType === item.type;
                
                return (
                    <div
                        key={item.type}
                        className={`data-type-item`}
                        onClick={() => handleTypeClick(item.type)}
                        onMouseEnter={() => setHoveredType(item.type)}
                        onMouseLeave={() => setHoveredType(null)}
                        data-type={item.type}
                    >
                        <div className="data-type-header">
                            <h3>{item.type}</h3>
                            <p>{item.subtitle}</p>
                        </div>
                        {isHovered && (
                            <img 
                                src={downArrow} 
                                alt="Toggle" 
                                className={`toggle-arrow-data-selector ${isExpanded ? 'rotated' : ''}`}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default DataTypeSelector;