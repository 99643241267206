// RecentHomeSalesTab.js
import React, { memo, useMemo } from 'react';

const getHomeSalesData = (locationData) => {
    return locationData?.geoType === "NEIGHBORHOOD"
        ? locationData?.neighborhoodHomeSales
        : locationData?.cityHomeSales;
};

export const RecentHomeSalesList = memo(({ locationData }) => {
    const sortedSales = useMemo(() => {
        const sales = getHomeSalesData(locationData) || [];
        return [...sales]
            .sort((a, b) => new Date(b.recordingDate) - new Date(a.recordingDate))
            .slice(0, 5); // Show exactly 5 most recent sales
    }, [locationData?.cityHomeSales]);

    if (!getHomeSalesData(locationData)?.length) {
        return <div>No recent sales data available</div>;
    }

    const formatCurrency = (amount) => {
        if (amount >= 1000000) {
            return `$${(amount / 1000000).toFixed(1)}M`;
        }
        if (amount >= 100000) {
            return `$${Math.round(amount / 1000)}K`;
        }
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(amount);
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            month: 'numeric',
            day: 'numeric'
        });
    };

    return (
        <div className="recent-sales-list">
            {sortedSales.map((sale) => (
                <div key={sale.id} className="summary-item">
                    <span className="summary-label">{sale.propertyAddressFull}</span>
                    <div className="sale-info-container">
                        <div className="sale-primary-info">
                            <strong>{formatCurrency(parseInt(sale.transferAmount))}</strong>
                            <span className="separator">•</span>
                            <span>{sale.bedroomsCount}b/{sale.bathCount}ba</span>
                            {sale.areaBuilding && (
                                <>
                                    <span className="separator">•</span>
                                    <span>{Math.round(sale.areaBuilding / 100) / 10}k sf</span>
                                </>
                            )}
                        </div>
                        <div className="sale-secondary-info">
                            <span>{formatDate(sale.recordingDate)}</span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
});

// Helper function for median calculation
const median = (numbers) => {
    if (!numbers.length) return null;
    const sorted = numbers.slice().sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);
    if (sorted.length % 2 === 0) {
        return (sorted[middle - 1] + sorted[middle]) / 2;
    }
    return sorted[middle];
};

export const RecentHomeSalesSummary = memo(({ locationData }) => {
    const calcStats = useMemo(() => {
        const sales = getHomeSalesData(locationData) || [];
        if (sales.length === 0) {
            return {
                averagePrice: '$0',
                medianPrice: '$0',
                averageSize: '0',
                pricePerSqFt: '$0',
                commonConfig: 'N/A',
                medianBeds: 0
            };
        }

        const prices = sales.map(s => parseInt(s.transferAmount));
        const avgPrice = Math.round(prices.reduce((a, b) => a + b, 0) / prices.length);
        const medianPrice = median(prices);

        // Calculate price per square foot for each property, then average
        const salesWithArea = sales.filter(s => s.areaBuilding && s.areaBuilding > 0);
        const pricesPerSqFt = salesWithArea.map(s => parseInt(s.transferAmount) / s.areaBuilding);

        const avgPricePerSqFt = pricesPerSqFt.length > 0
            ? Math.round(pricesPerSqFt.reduce((a, b) => a + b, 0) / pricesPerSqFt.length)
            : 0;

        // Calculate average home size
        const avgSize = salesWithArea.length > 0
            ? Math.round(salesWithArea.reduce((sum, sale) => sum + sale.areaBuilding, 0) / salesWithArea.length)
            : 0;

        // Find most common bed/bath configuration
        const configCounts = sales.reduce((acc, sale) => {
            if (sale.bedroomsCount && sale.bathCount) {
                const config = `${sale.bedroomsCount}b/${sale.bathCount}ba`;
                acc[config] = (acc[config] || 0) + 1;
            }
            return acc;
        }, {});

        const commonConfig = Object.entries(configCounts)
            .sort((a, b) => b[1] - a[1])[0]?.[0] || 'N/A';

        return {
            averagePrice: new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(avgPrice),
            medianPrice: new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(medianPrice),
            averageSize: `${Math.round(avgSize / 100) / 10}k sf`,
            pricePerSqFt: new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(avgPricePerSqFt),
            commonConfig: commonConfig,
            medianBeds: median(sales.map(s => s.bedroomsCount).filter(Boolean))
        };
    }, [locationData]);

    if (!getHomeSalesData(locationData)?.length) {
        return <div>No sales data available for summary</div>;
    }

    return (
        <div>
            <div className="summary-item">
                <span className="summary-label">Average Sale Price:</span>
                <span className="summary-value">{calcStats.averagePrice}</span>
            </div>
            <div className="summary-item">
                <span className="summary-label">Median Sale Price:</span>
                <span className="summary-value">{calcStats.medianPrice}</span>
            </div>
            <div className="summary-item">
                <span className="summary-label">Average Size:</span>
                <span className="summary-value">{calcStats.averageSize}</span>
            </div>
            <div className="summary-item">
                <span className="summary-label">Price per Square Foot:</span>
                <span className="summary-value">{calcStats.pricePerSqFt}</span>
            </div>
            <div className="summary-item">
                <span className="summary-label">Typical Configuration:</span>
                <span className="summary-value">{calcStats.commonConfig}</span>
            </div>
            <div className="summary-item">
                <span className="summary-label">Median Bedrooms:</span>
                <span className="summary-value">{calcStats.medianBeds}</span>
            </div>
            <div style={{
                fontSize: '8px',
                color: '#666666',
                fontStyle: 'italic',
                paddingTop: '0.3vh',
                paddingLeft: '0.3vw'
            }}>
                *Statistics based on the most recent 50 sales
            </div>
        </div>
    );
});

export const RecentHomeSalesOverview = ({ locationData }) => {
    if (!getHomeSalesData(locationData)) {
        return <div>No home sales data available</div>;
    }

    return (
        <div className="p-4 bg-white rounded-lg shadow-md">
            <style jsx global>{`
          :root {
              --summary-title-size: 1.5vh;
              --summary-item-size: 1.5vh;
              --summary-value-size: 1.5vh;
          }
          
          @media (min-width: 768px) {
            :root {
              --summary-title-size: 1.5vh;
              --summary-item-size: 1.5vh;
              --summary-value-size: 1.5vh;
            }
          }
          
          .recent-sales-list .summary-item {
            font-size: var(--summary-item-size);
          }
  
          .sale-info-container {
            display: flex;
            flex-direction: column;
            gap: 0.25vh;
          }
  
          .sale-primary-info {
            display: flex;
            align-items: center;
            gap: 0.5vw;
          }
  
          .sale-secondary-info {
            font-size: calc(var(--summary-item-size) * 0.9);
            color: #666;
          }
  
          .separator {
            color: #666;
            margin: 0 0.25vw;
          }
        `}</style>

            <div className="grid grid-cols-1 gap-4">
                {/* Sales Summary Statistics */}
                <div className="bg-gray-100 p-4 rounded-lg">
                    <h3 className="text-xl font-semibold mb-2">Sales Summary</h3>
                    <RecentHomeSalesSummary locationData={locationData} />
                </div>

                {/* Recent Sales List */}
                <div className="bg-gray-100 p-4 rounded-lg">
                    <h3 className="text-xl font-semibold mb-2">Latest Sales</h3>
                    <RecentHomeSalesList locationData={locationData} />
                </div>
            </div>
        </div>
    );
};

const RecentHomeSalesTab = ({ ContentBox, locationData }) => {
    console.log('RecentHomeSalesTab rendering with data:', locationData);

    return (
        <>
            <ContentBox
                title="Recent Sales Summary"
                key="RecentHomeSalesSummary"
                subtype="recentHomeSales"
                contentType="RecentHomeSalesSummary"
            >
                <RecentHomeSalesSummary locationData={locationData} />
            </ContentBox>
            <ContentBox
                title="Latest Home Sales"
                key="RecentHomeSalesList"
                subtype="recentHomeSales"
                contentType="RecentHomeSalesList"
            >
                <RecentHomeSalesList locationData={locationData} />
            </ContentBox>
        </>
    );
};

export default RecentHomeSalesTab;