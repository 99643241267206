import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get_current_user } from '../actions/auth';
import LoadingSpinner from './LoadingSpinner';

const AdminRoute = ({ component: Component }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAdminAccess = async () => {
            try {
                const backendUser = await get_current_user();

                if (!backendUser?.email?.endsWith('@realanalytica.com')) {
                    navigate('/dashboard');
                    return;
                }

                setIsAuthorized(true);
            } catch (error) {
                console.error('Error checking admin access:', error);
                navigate('/login');
            } finally {
                setIsLoading(false);
            }
        };

        checkAdminAccess();
    }, [navigate]);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return isAuthorized ? <Component /> : null;
};

export default AdminRoute;