import React from 'react';

const getRankingPhrase = (metric, rank, total) => {
    const phrases = {
        crimeIndex: {
            text: "Ranked %r safest"
        },
        educationBachelorDegreePct: {
            text: "%r most educated area"
        },
        medianHouseholdIncome: {
            text: "%r highest household income"
        },
        populationDensitySqMi: {
            text: "%r most densely populated"
        },
        medianAge: {
            text: "%r youngest demographic"
        },
        occWhiteCollarPct: {
            text: "%r in professional employment"
        }
    };

    if (!rank) return null;

    const rankText = rank === 1 ? "1st" : `${rank}${getOrdinalSuffix(rank)}`;
    return phrases[metric]?.text.replace("%r", rankText);
};

const getOrdinalSuffix = (num) => {
    const j = num % 10;
    const k = num % 100;
    if (j === 1 && k !== 11) return "st";
    if (j === 2 && k !== 12) return "nd";
    if (j === 3 && k !== 13) return "rd";
    return "th";
};

const RankingsDisplay = ({ rankingsData, isLoading, error }) => {
    console.log(rankingsData);
    if (isLoading) {
        return (
            <div className="rankings-container">
                <div className="rankings-header">
                    <h4 className="rankings-title">Area Rankings</h4>
                </div>
                <div className="rankings-content">
                    <div className="loading-indicator">Loading rankings...</div>
                </div>
            </div>
        );
    }

    if (error || !rankingsData) {
        return (
            <div className="rankings-container">
                <div className="rankings-header">
                    <h4 className="rankings-title">Notable Rankings</h4>
                </div>
                <div className="rankings-content">
                    <div className="error-message">Unable to load rankings</div>
                </div>
            </div>
        );
    }

    // Map of metrics we want to display and their corresponding rank fields
    const metricsToCheck = [
        { field: 'crimeIndex', rankField: 'crimeIndexRank' },
        { field: 'educationBachelorDegreePct', rankField: 'educationBachelorDegreePctRank' },
        { field: 'medianHouseholdIncome', rankField: 'medianHouseholdIncomeRank' },
        { field: 'populationDensitySqMi', rankField: 'populationDensitySqMiRank' },
        { field: 'medianAge', rankField: 'medianAgeRank' },
        { field: 'occWhiteCollarPct', rankField: 'occWhiteCollarPctRank' }
    ];

    // Get available rankings
    const availableRankings = metricsToCheck
        .map(({ field, rankField }) => ({
            metric: field,
            rank: rankingsData[rankField]
        }))
        .filter(({ rank }) => rank !== null && rank !== undefined)
        // Sort by rank (lower is better)
        .sort((a, b) => a.rank - b.rank)
        // Take top 4
        .slice(0, 4);

    return (
        <div className="rankings-container">
            <div className="rankings-header">
                <h4 className="rankings-title">Notable Rankings</h4>
            </div>
            <div className="rankings-content">
                {availableRankings.map(({ metric, rank }) => (
                    <div key={metric} className="summary-item">
                        <span className="summary-label">
                            {getRankingPhrase(metric, rank)}
                        </span>
                    </div>
                ))}
                <div className="rankings-footer">
                    Among {rankingsData.totalLocationsInParent} {rankingsData?.geoType === "NEIGHBORHOOD" ? "neighborhoods" : "cities"}
                </div>
            </div>
        </div>
    );
};

export default RankingsDisplay;