// src/App.js
import awsConfig from './amplify-config';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import { get_current_user } from './actions/auth';
import { getCurrentUser, fetchAuthSession, signOut, signIn } from '@aws-amplify/auth';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import './App.css';
import Dashboard from './pages/Dashboard';
import HomePage from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import VerificationPage from './pages/VerificationPage';
import AdminPage from './pages/AdminPage';
import About from './pages/About';
import Blog from './pages/Blog'
import ViewUseCases from './pages/ViewUseCases';
import BlogPost from './components/BlogPost';
import CheckoutSuccess from './components/CheckoutSuccess';
import PrivacyPolicy from './pages/PrivacyPolicy';

import ResetPassword from './containers/ResetPassword';
import ResetPasswordConfirm from './containers/ResetPasswordConfirm';
import Google from './components/Google';
import Account from './components/Account';
import Contact from './pages/Contact'
import Navbar from './components/Navbar';

import { MarketModeProvider } from './contexts/MarketModeContext';
import FeedbackBubble from './components/FeedbackBubble';
import ProtectedRoute from './components/ProtectedRoute';
import AdminRoute from './components/AdminRoute';
import NotFound from './components/NotFound';
import { createBackendUser, checkUserExists } from './actions/auth';
import LoadingSpinner from './components/LoadingSpinner';

Amplify.configure(awsConfig);

let posthog_api_key = `${process.env.REACT_APP_POSTHOG_API_KEY}`;

// Initialize PostHog with error handling
try {
  const initPostHog = async () => {
    // Check if user is logged in
    try {
      const user = await getCurrentUser();
      const email = user.signInDetails?.loginId;

      // Don't initialize PostHog for internal users
      if (email?.endsWith('@realanalytica.com')) {
        return;
      }
    } catch (error) {
      // No user logged in, proceed with PostHog init
    }

    // Initialize PostHog for non-internal users
    posthog.init(
      posthog_api_key,
      {
        api_host: 'https://us.i.posthog.com',
        capture_pageview: true,
        capture_performance: true,
        autocapture: true,
        session_recording: {
          enabled: true,
          minimum_duration: 3000,
        },
        persistence: 'localStorage',
        person_profiles: 'always',
        loaded: (posthog) => {
          if (process.env.NODE_ENV === 'development') {
            // console.log('PostHog initialized successfully');
          }
        },
        on_xhr_error: (xhr, url) => {
          if (xhr.status === 0) {
            posthog.opt_out_capturing();
          }
        }
      }
    );
  };

  initPostHog();

} catch (error) {
  console.warn('Failed to initialize PostHog:', error);
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  useEffect(() => {
    checkUser();
    const authListener = Hub.listen('auth', async ({ payload: { event, data } }) => {
      console.log("Auth event received:", event, "with data:", data);
      switch (event) {
        case 'signInWithRedirect':
          console.log("Inside signInWithRedirect case");
          setIsAuthenticating(true);
          break;

        case 'signedIn':
          try {
            const user = await getCurrentUser();
            const session = await fetchAuthSession();
            const email = session.tokens?.idToken?.payload?.email;

            if (!email) {
              setIsAuthenticating(false);
              setTimeout(() => {
                alert('Unable to retrieve email from Google account. Please try again or use email/password login.');
                window.location.href = '/login';
              }, 100);
              return;
            }

            // Check if this is a Google sign-in by looking at the current sign-in method
            const isCurrentlyGoogleSignIn = user.signInDetails?.loginId?.includes('google_') ||
              session.tokens?.idToken?.payload['cognito:username']?.startsWith('google_');

            // Only perform the provider check if this is a Google sign-in
            if (isCurrentlyGoogleSignIn) {
              try {
                // Check if this user exists with email/password
                const signInAttempt = await signIn({
                  username: email,
                  password: 'dummy_password_for_check'
                }).catch(e => e);

                if (signInAttempt.name === 'NotAuthorizedException') {
                  // User exists with email/password
                  setIsAuthenticating(false);
                  setTimeout(() => {
                    alert('This email is already registered with a password. Please use the email/password login option instead of Google Sign-In.');
                    window.location.href = '/login';
                  }, 100);
                  await signOut();
                  return;
                }

                // If we get here, either the user doesn't exist or is a valid Google user
                const backendUser = await get_current_user();

                if (!backendUser || !backendUser.id) {
                  await createBackendUser(
                    user.userId,
                    email,
                    session.tokens.idToken.payload?.given_name || 'New',
                    session.tokens.idToken.payload?.family_name || 'User',
                    { tokens: { idToken: session.tokens.idToken } }
                  );
                }

                setIsAuthenticated(true);
                setIsAuthenticating(false);
                window.location.href = '/account?tab=Dashboard';
              } catch (error) {
                console.error('Error in Google sign-in flow:', error);
                setIsAuthenticating(false);
                setTimeout(() => {
                  if (error.message?.includes('backend user')) {
                    alert('Error creating user profile. Please try again or contact support if the issue persists.');
                  } else if (error.name === 'UserNotFoundException') {
                    alert('No account found with this email. Please sign up first.');
                  } else {
                    alert('There was an error processing your Google Sign-In. Please try again or use email/password login.');
                  }
                  window.location.href = '/login';
                }, 100);
                await signOut();
              }
            } else {
              // This is a regular email/password login, let it proceed
              setIsAuthenticated(true);
              setIsAuthenticating(false);
            }
          } catch (error) {
            console.error('Error in signed in flow:', error);
            setIsAuthenticating(false);
            setTimeout(() => {
              if (error.name === 'UserNotFoundException') {
                alert('No account found with this email. Please sign up first.');
              } else if (error.name === 'NotAuthorizedException') {
                alert('Incorrect email or password. Please try again.');
              } else if (error.name === 'UserNotConfirmedException') {
                alert('Please verify your email before logging in. Check your inbox for a verification link.');
              } else {
                alert('There was an error signing in. Please try again.');
              }
              window.location.href = '/login';
            }, 100);
            await signOut();
          }
          break;

        case 'signOut':
          setIsAuthenticated(false);
          setIsAuthenticating(false);
          break;
      }
    });

    return () => {
      authListener();
    };
  }, []);

  async function checkUser() {
    try {
      const user = await getCurrentUser();

      try {
        posthog.identify(user.userId, {
          email: user.signInDetails?.loginId,
          amplifyId: user.userId,
          authProvider: user.signInDetails?.loginId.includes('@') ? 'email' : 'google',
        });

        try {
          posthog.capture('user_signed_in', {
            provider: user.signInDetails?.loginId.includes('@') ? 'email' : 'google',
            timestamp: new Date().toISOString()
          });
        } catch (captureError) {
          console.warn('Failed to capture sign in event:', captureError);
        }
      } catch (identifyError) {
        console.error('Failed to identify user:', identifyError);
      }
    } catch (userError) {
      console.error('Error getting user details:', userError);
    }
  }

  if (isAuthenticating) {
    return <LoadingSpinner />;
  }

  return (
    <PostHogProvider client={posthog}>
      <Router>
        <Navbar isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
        <MarketModeProvider>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
            <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="/account" element={<ProtectedRoute component={Account} />} />
            {/* <Route path="/about" element={<About />} /> */}
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/verify" element={<VerificationPage />} />
            <Route path="/google" element={<Google />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/reset-password-confirm" element={<ResetPasswordConfirm />} />
            <Route path="/password/reset/confirm/:uid/:token" element={<ResetPasswordConfirm />} />
            <Route path="/success" element={<CheckoutSuccess />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/admin" element={<AdminRoute component={AdminPage} />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </MarketModeProvider>
        {/* <FeedbackBubble /> */}
      </Router>
    </PostHogProvider>
  );
}

export default App;
