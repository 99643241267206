/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const isLocalhost = window.location.hostname === "localhost";
const redirectUrl = isLocalhost ?
    "http://localhost:3000" :
    "https://realanalytica.com";

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:5095667e-45dd-4f2b-813d-1001e883a3cf",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_6bTUTqToo",
    "aws_user_pools_web_client_id": "24mkopidq1e8iuo99gv9iminhq",
    "oauth": {
        "domain": "realanalytica-auth.auth.us-east-1.amazoncognito.com",
        "scope": ["email", "profile", "openid"],
        "redirectSignIn": redirectUrl,
        "redirectSignOut": redirectUrl,
        "responseType": "code"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": ["Google"],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://6dvxgn3zkj.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
