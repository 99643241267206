import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import MarketOverlays from '../components/MarketOverlays';

const Dashboard = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [mode, setMode] = useState('simple');
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const isMobile = window.innerWidth <= 768;

    const handleMobileRedirect = (attemptedMode) => {
        const modeName = attemptedMode === 'custom' ? 'CustomCanvas™' : 'DeepDive™';
        alert(`${modeName} is currently under development for mobile devices. Please use a desktop browser to access this feature.`);
        navigate('/account?tab=Dashboard', { replace: true });
    };

    useEffect(() => {
        const modeParam = searchParams.get('mode');

        // If on mobile and trying to access custom or research mode, show alert and redirect
        if (isMobile && (modeParam === 'custom' || modeParam === 'research')) {
            handleMobileRedirect(modeParam);
            return;
        }

        // Otherwise set the mode if it's valid
        if (modeParam && ['simple', 'custom', 'research'].includes(modeParam)) {
            setMode(modeParam);
        }
    }, [searchParams, navigate, isMobile]);

    // Add resize listener to handle orientation changes
    useEffect(() => {
        const handleResize = () => {
            const isMobileNow = window.innerWidth <= 768;
            if (isMobileNow && (mode === 'custom' || mode === 'research')) {
                handleMobileRedirect(mode);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [navigate, mode]);

    return (
        <div>
            <MarketOverlays
                mode={mode}
                setMode={setMode}
                selectedFeatures={selectedFeatures}
            />
        </div>
    );
};

export default Dashboard;