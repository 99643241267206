import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import HeroSection from '../components/HeroSection.js';
import DashboardSection from '../components/DashboardSection.js';
import SheetsSection from '../components/SheetsSection.js';
import SheetsExamples from '../components/SheetsExamples.js';
import UserDashboardHomePage from '../components/UserDashboardHomePage.js';
import MarketingSection from '../components/MarketingSection.js';
import TestimonialSection from '../components/TestimonialSection.js';
import Footer from '../components/Footer.js';
import './Home.css';
import { checkAuthStatus } from '../actions/auth';

const HomePage = () => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const status = await checkAuthStatus();
        setIsAuthenticated(status.isAuthenticated);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    checkAuth();

    const searchParams = new URLSearchParams(location.search);
    const signupSuccess = searchParams.get('verification');
    if (signupSuccess === 'success') {
      alert('Your account has been successfully verified!');
    }
  }, [location]);

  return (
    <div className="homepage-container">
      <HeroSection isAuthenticated={isAuthenticated} />
      {/* <DashboardSection /> */}
      <UserDashboardHomePage />
      <SheetsSection isAuthenticated={isAuthenticated} />
      {/* <MarketingSection /> */}
      {/* <TestimonialSection isAuthenticated={isAuthenticated} /> */}
      <Footer />
    </div>
  );
};
export default HomePage;