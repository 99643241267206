import React from 'react';
import { AlgorithmOverview } from './contentTabs/AlgoTab';
import { PopulationOverview } from './contentTabs/PopulationTab';
import { RecentHomeSalesOverview } from './contentTabs/RecentHomeSalesTab';
import { SchoolsOverview } from './contentTabs/SchoolsTab';
import { IncomeOverview } from './contentTabs/IncomeTab';
import { CrimeOverview } from './contentTabs/CrimeTab';
import { LocationMetricsOverview } from './contentTabs/LocationMetricsTab';
import { DynamicComparisonOverview } from './contentTabs/DynamicNeighborhoodComparisonTab';
import DataTypeSelector from './DataTypeSelector';
import './ResearchPageCard.css';
import downArrow from '../assets/down-arrow-RA.svg';

const ResearchPageGraphicBox = ({
    selectedDataType,
    onDataTypeChange,
    neighborhood,
    locationData,
    rankingsData,
    startDate,
    endDate,
    isExpanded,
    setIsExpanded
}) => {
    const handleTypeSelect = (type) => {
        if (selectedDataType === type) {
            setIsExpanded(!isExpanded);
        } else {
            onDataTypeChange(type);
        }
    };

    const renderContent = () => {
        if (!locationData) {
            return <div>No data available</div>;
        }

        switch (selectedDataType) {
            case "Algorithm":
                return (
                    <AlgorithmOverview
                        locationData={locationData}
                        startDate={startDate}
                        endDate={endDate}
                    />
                );
            case "Population":
                return (
                    <PopulationOverview
                        locationData={locationData}
                    />
                );
            case "Income":
                return (
                    <IncomeOverview
                        locationData={locationData}
                    />
                );
            case "Crime":
                return (
                    <CrimeOverview
                        locationData={locationData}
                    />
                );
            case "Location Metrics":
                return (
                    <LocationMetricsOverview
                        locationData={locationData}
                    />
                );
            case "Recent Home Sales":
                return (
                    <RecentHomeSalesOverview
                        locationData={locationData}
                    />
                );
            case "School":
                return (
                    <SchoolsOverview
                        locationData={locationData}
                    />
                );
            case "Dynamic Comparison":
                return (
                    <DynamicComparisonOverview
                        rankingsData={rankingsData}
                        selectedLocationId={neighborhood.id}
                    />
                );
            default:
                return <div>Select a data type</div>;
        }
    };

    return (
        <div className="research-page-graphic-box">
            {isExpanded ? (
                <div className="expanded-view">
                    <div className="expanded-header" onClick={() => setIsExpanded(false)}>
                        <h3>{selectedDataType}</h3>
                        <img 
                            src={downArrow} 
                            alt="Toggle" 
                            className="toggle-arrow rotated"
                        />
                    </div>
                    {renderContent()}
                </div>
            ) : (
                <DataTypeSelector
                    selectedType={selectedDataType}
                    onTypeSelect={handleTypeSelect}
                    isExpanded={isExpanded}
                />
            )}
        </div>
    );
};

export default ResearchPageGraphicBox;