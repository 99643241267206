import React, { useState, useEffect } from 'react';
import './Contact.css';
import rightArrow from '../assets/right-arrow-RA.svg';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [expandedVersion, setExpandedVersion] = useState(null);
  const [versions, setVersions] = useState([]);

  // Function to format version number from filename
  const formatVersionNumber = (filename) => {
    // Extract the number from filename (e.g., "realanalytica100" -> "1.00")
    const versionNum = filename.match(/\d+/)[0];
    return `${versionNum.slice(0, -2)}.${versionNum.slice(-2)}`;
  };

  // Load all version files from the version_updates folder
  useEffect(() => {
    // Get all txt files from the version_updates folder
    const importAll = (r) => {
      let files = {};
      r.keys().forEach((key) => {
        files[key] = r(key);
      });
      return files;
    };

    const versionFiles = importAll(
      require.context('../assets/version_updates', false, /\.txt$/)
    );

    // Process each file
    Promise.all(
      Object.entries(versionFiles).map(async ([filename, path]) => {
        try {
          const response = await fetch(path);
          const text = await response.text();
          const versionNumber = formatVersionNumber(filename.split('/').pop().replace('.txt', ''));

          return {
            id: `version${versionNumber.replace('.', '')}`,
            title: `Version ${versionNumber}`,
            changes: text.split('\n').filter(line => line.trim() !== '')
          };
        } catch (error) {
          console.error(`Error loading version file ${filename}:`, error);
          return null;
        }
      })
    ).then(results => {
      // Filter out any failed loads and sort by version number (descending)
      const validResults = results.filter(result => result !== null);
      validResults.sort((a, b) => {
        const versionA = parseFloat(a.title.replace('Version ', ''));
        const versionB = parseFloat(b.title.replace('Version ', ''));
        return versionB - versionA;
      });
      setVersions(validResults);
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href = `mailto:admin@realanalytica.com?subject=${encodeURIComponent(formData.subject)}&body=${encodeURIComponent(`Name: ${formData.name}\nEmail: ${formData.email}\n\n${formData.message}`)}`;
  };

  const toggleVersion = (versionId) => {
    if (expandedVersion === versionId) {
      setExpandedVersion(null);
    } else {
      setExpandedVersion(versionId);
    }
  };

  return (
    <div className="contact-page">
      <div className="contact-section">
        <div className="contact-content">
          <div className="content-wrapper">
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit} className="contact-form">
              <div className="form-group-contact">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div className="form-group-contact">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email address"
                  required
                />
              </div>
              <div className="form-group-contact">
                <label htmlFor="subject">Subject</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  placeholder="Enter subject"
                  required
                />
              </div>
              <div className="form-group-contact">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Enter your message"
                  required
                />
              </div>
              <button type="submit" className="submit-button">Send Message</button>
            </form>

            <h2>Our Progress</h2>
            <div className="version-history">
              {versions.map((version) => (
                <div key={version.id} className="version-item">
                  <div
                    className={`version-header ${expandedVersion === version.id ? 'expanded' : ''}`}
                    onClick={() => toggleVersion(version.id)}
                  >
                    <span className="version-title">{version.title}</span>
                    <img
                      src={rightArrow}
                      alt="expand"
                      className={`arrow-icon ${expandedVersion === version.id ? 'rotated' : ''}`}
                    />
                  </div>
                  <div className={`version-content ${expandedVersion === version.id ? 'expanded' : ''}`}>
                    <ul>
                      {version.changes.map((change, index) => (
                        <li key={index}>{change}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;