import React, { useState, useEffect } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import './AdminPage.css';

const MetricCard = ({ title, value }) => (
    <div className="admin-metric-card">
        <h3 className="admin-metric-card__title">{title}</h3>
        <p className="admin-metric-card__value">{value}</p>
    </div>
);

const DataCard = ({ title, children }) => (
    <div className="admin-data-card">
        <h3 className="admin-data-card__title">{title}</h3>
        <div className="admin-data-card__content">
            {children}
        </div>
    </div>
);

const ListCard = ({ title, items }) => (
    <div className="admin-metric-card">
        <h3 className="admin-metric-card__title">{title}</h3>
        <ul className="admin-metric-card__list">
            {items.map((item, index) => (
                <li key={index} className="admin-metric-card__list-item">
                    <span className="admin-metric-card__list-item-name">{item.name}</span>
                    <span className="admin-metric-card__list-item-value">{item.value}</span>
                </li>
            ))}
        </ul>
    </div>
);

const AdminPage = () => {
    // Mock data - replace with API calls later
    const [metrics, setMetrics] = useState({
        totalUsers: 1250,
        activeUsers: 890,
        userGrowth: [
            { date: new Date(2024, 0, 1), users: 1000 },
            { date: new Date(2024, 1, 1), users: 1100 },
            { date: new Date(2024, 2, 1), users: 1250 }
        ],
        snapSheets: {
            exported: 450,
            topBoxes: [
                { name: "Valuation Index", value: 245 },
                { name: "Population Demographics", value: 198 },
                { name: "Crime Analysis", value: 156 },
                { name: "School Statistics", value: 134 },
                { name: "Income Distribution", value: 112 }
            ]
        },
        customCanvas: {
            exported: 320,
            topBoxes: [
                { name: "Location Metrics", value: 189 },
                { name: "Market Trends", value: 167 },
                { name: "Demographics", value: 145 },
                { name: "Education", value: 123 },
                { name: "Crime Stats", value: 98 }
            ]
        },
        deepDive: {
            topCities: [
                { name: "New York", value: 145 },
                { name: "Los Angeles", value: 123 },
                { name: "Chicago", value: 98 },
                { name: "Houston", value: 87 },
                { name: "Phoenix", value: 76 }
            ],
            topNeighborhoods: [
                { name: "Manhattan - Upper East Side", value: 89 },
                { name: "Brooklyn - Williamsburg", value: 76 },
                { name: "LA - Beverly Hills", value: 65 },
                { name: "Chicago - Lincoln Park", value: 54 },
                { name: "Seattle - Capitol Hill", value: 43 }
            ],
            dataCategories: [
                { name: "Demographics", value: 567 },
                { name: "Housing", value: 489 },
                { name: "Income", value: 432 },
                { name: "Education", value: 378 },
                { name: "Crime", value: 345 },
                { name: "Employment", value: 289 },
                { name: "Transportation", value: 234 }
            ]
        }
    });

    return (
        <div className="admin-dashboard">
            <h1 className="admin-dashboard__title">Admin Dashboard</h1>

            <h2 className="admin-dashboard__section-title">User Dashboard</h2>
            <div className="admin-dashboard__metrics-grid">
                <MetricCard title="Total Users" value={metrics.totalUsers} />
                <MetricCard title="Active Users" value={metrics.activeUsers} />
            </div>

            <div className="admin-dashboard__data-grid">
                <DataCard title="User Growth">
                    <div style={{ width: '100%', height: 300 }}>
                        <LineChart
                            xAxis={[{
                                data: metrics.userGrowth.map(d => d.date.getTime()),
                                scaleType: 'time'
                            }]}
                            series={[{
                                data: metrics.userGrowth.map(d => d.users),
                                area: true,
                            }]}
                            height={300}
                        />
                    </div>
                </DataCard>
            </div>

            <h2 className="admin-dashboard__section-title">SnapSheets</h2>
            <div className="admin-dashboard__metrics-grid">
                <MetricCard title="Sheets Exported" value={metrics.snapSheets.exported} />
                <ListCard title="Top 5 Content Boxes Used" items={metrics.snapSheets.topBoxes} />
            </div>

            <h2 className="admin-dashboard__section-title">CustomCanvas</h2>
            <div className="admin-dashboard__metrics-grid">
                <MetricCard title="Sheets Exported" value={metrics.customCanvas.exported} />
                <ListCard title="Top 5 Content Boxes Used" items={metrics.customCanvas.topBoxes} />
            </div>

            <h2 className="admin-dashboard__section-title">DeepDive</h2>
            <div className="admin-dashboard__metrics-grid">
                <ListCard title="Top 5 Cities Selected" items={metrics.deepDive.topCities} />
                <ListCard title="Top 5 Neighborhoods Selected" items={metrics.deepDive.topNeighborhoods} />
                <ListCard title="Data Categories Selected" items={metrics.deepDive.dataCategories} />
            </div>
        </div>
    );
};

export default AdminPage;