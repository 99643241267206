// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signOut } from '@aws-amplify/auth';
import { get_current_user, checkAuthStatus } from '../actions/auth';
import './Navbar.css';
import blueBetaLogo from '../assets/RA-logo-1-beta.svg';
import blueBetaIcon from '../assets/RA-logo-2-beta.svg'
import Button from './ui/Button';

const Navbar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isHamburgerVisible, setIsHamburgerVisible] = useState(false);

    const isAccountPage = location.pathname === '/account';
    const isDashboardPage = location.pathname === '/dashboard';
    const isSignupPage = location.pathname === '/signup';

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const status = await checkAuthStatus();
                setIsAuthenticated(status.isAuthenticated);
            } catch (error) {
                setIsAuthenticated(false);
            }
        };

        checkAuth();
    }, [location.pathname]); // Re-check when route changes

    const logoutUser = async () => {
        try {
            await signOut();
            setIsAuthenticated(false);
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsHamburgerVisible(window.innerWidth <= 900);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const guestLinks = () => (
        <>
            <Link
                to="/"
                onClick={toggleSidebar}
                title="RealAnalytica Home - Analytics Platform"
                aria-label="Return to RealAnalytica homepage"
            >
                Home
            </Link>
            <Link
                to="/blog"
                onClick={toggleSidebar}
                title="RealAnalytica Blog - Latest Analytics Insights"
                aria-label="Read our analytics blog posts"
            >
                Blog
            </Link>
            <Link
                to="/contact"
                onClick={toggleSidebar}
                title="Contact RealAnalytica Support Team"
                aria-label="Contact our support team"
            >
                Contact
            </Link>
            <Link
                to="/signup"
                onClick={toggleSidebar}
                title="Sign Up for RealAnalytica Analytics Platform"
                aria-label="Create a new analytics account"
            >
                <Button
                    variant="2"
                    size="s"
                    className="navbar-signup-btn"
                    style={{ margin: 0 }}
                >
                    Sign Up
                </Button>
            </Link>
            <Link
                to="/login"
                onClick={toggleSidebar}
                title="Sign In to RealAnalytica Dashboard"
                aria-label="Sign in to your account"
            >
                Login
            </Link>
        </>
    );

    const authLinks = () => (
        <>
            <Link
                to="/"
                onClick={toggleSidebar}
                title="RealAnalytica Home"
                aria-label="Return to homepage"
            >
                Home
            </Link>
            <Link
                to="/account?tab=Dashboard"
                onClick={toggleSidebar}
                title="Your RealAnalytica Dashboard"
                aria-label="View your analytics dashboard"
            >
                Dashboard
            </Link>
            <Link
                to="/blog"
                onClick={toggleSidebar}
                title="RealAnalytica Analytics Blog"
                aria-label="Read our blog posts"
            >
                Blog
            </Link>
            <Link
                to="/contact"
                onClick={toggleSidebar}
                title="Contact RealAnalytica Support"
                aria-label="Contact our support team"
            >
                Contact
            </Link>
            <Link
                to="/account?tab=Settings"
                onClick={toggleSidebar}
                title="Account Settings"
                aria-label="Manage your account settings"
            >
                Account
            </Link>
            <Link
                to="/"
                onClick={logoutUser}
                title="Sign Out"
                aria-label="Sign out of your account"
            >
                Sign Out
            </Link>
        </>
    );

    if (isDashboardPage) {
        return null;
    }

    return (
        <nav className={`navbar ${isAccountPage ? 'account-page' : ''} ${isSignupPage ? 'signup-page' : ''}`}>
            <div className="navbar-container">
                <div className="navbar-logo">
                    <Link to="/" title="RealAnalytica Home" aria-label="Return to homepage">
                        {isHamburgerVisible ? (
                            <img src={blueBetaIcon} alt="Hamburger Logo" className="logoIcon" />
                        ) : (
                            <img src={blueBetaLogo} alt="RealAnalytica Logo" className="logo" />
                        )}
                    </Link>
                </div>
                <div className={`navbar-links ${isSidebarOpen ? 'open' : ''}`}>
                    {isAuthenticated ? authLinks() : guestLinks()}
                </div>
                <div className="hamburger" onClick={toggleSidebar}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;