import React from 'react';
import { useNavigate } from 'react-router-dom';
import './VisitOtherProducts.css';
import simpleSheets from '../assets/simple-sheets.svg';
import customSheets from '../assets/custom-sheets.svg';
import detailedResearch from '../assets/detailed-research.svg';
import Button from './ui/Button'

const productData = {
    simpleSheets: {
        image: simpleSheets,
        title: "SnapSheets",
        description: "Generate one-page summaries with hyper-local insights, made ready to share.",
        mode: "simple",
        disableOnMobile: false
    },
    customSheets: {
        image: customSheets,
        title: "CustomCanvas",
        description: "Customize multi-page reports to match your brand and dive deeper into neighborhood trends.",
        mode: "custom",
        disableOnMobile: true
    },
    detailedResearch: {
        image: detailedResearch,
        title: "DeepDive",
        description: "Compare neighborhoods side by side with detailed analytics to enhance your recommendations.",
        mode: "research",
        disableOnMobile: true
    }
};

const VisitOtherProducts = ({ productType }) => {
    const navigate = useNavigate();
    const product = productData[productType];
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

    React.useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClick = () => {
        if (isMobile && product.disableOnMobile) {
            return;
        }
        navigate(`/dashboard?mode=${product.mode}`);
    };

    const isDisabled = isMobile && product.disableOnMobile;

    return (
        <div className="visit-other-products">
            <div className="visit-other-products__image-container">
                <img
                    src={product.image}
                    alt={product.title}
                    className="visit-other-products__image"
                />
            </div>
            <div className="visit-other-products__content">
                <p className="visit-other-products__description">{product.description}</p>
                <Button
                    variant="1" 
                    onClick={handleClick}
                    disabled={isDisabled}
                >
                    {product.title}
                    {isDisabled && <span className="mobile-only-text">(Desktop Only)</span>}
                </Button>
            </div>
        </div>
    );
};

export default VisitOtherProducts;