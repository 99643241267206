import React, { useState, useEffect } from "react";
import { useTransition, animated } from "react-spring";
import "./FeaturedDataInsights.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const FeaturedDataInsights = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('/static/blog/index.json');
        if (!response.ok) {
          throw new Error('Failed to fetch blog posts');
        }
        const data = await response.json();
        // Sort posts by date to get the most recent ones
        const sortedPosts = data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setPosts(sortedPosts.slice(0, 3)); // Get the 3 most recent posts
      } catch (err) {
        console.error('Error fetching blog posts:', err);
        setError('Failed to fetch blog posts');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const hasMultipleImages = posts.length > 1;

  const transitions = useTransition(currentIndex, {
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: { opacity: 0, transform: "translate3d(-50%,0,0)" },
  });

  const handlePrevious = () => {
    if (!hasMultipleImages) return;
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? posts.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    if (!hasMultipleImages) return;
    setCurrentIndex((prevIndex) => (prevIndex + 1) % posts.length);
  };

  if (isLoading) {
    return <div className="featured-data-insights">Loading...</div>;
  }

  if (error || posts.length === 0) {
    return <div className="featured-data-insights">No posts available</div>;
  }

  return (
    <div className="featured-data-insights">
      <h2 className="featured-data-insights__title">{posts[currentIndex].title}</h2>
      <div className="featured-data-insights__content">
        {hasMultipleImages && (
          <button
            className="featured-data-insights__arrow featured-data-insights__arrow--left"
            onClick={handlePrevious}
          >
            <FaChevronLeft />
          </button>
        )}
        <div
          className={`featured-data-insights__image-container ${!hasMultipleImages ? "single-image" : ""
            }`}
        >
          {transitions((style, index) => (
            <animated.div
              style={style}
              className="featured-data-insights__animated-container"
            >
              <img
                src={posts[index].thumbnail}
                alt={posts[index].title}
                className="featured-data-insights__image"
              />
            </animated.div>
          ))}
        </div>
        {hasMultipleImages && (
          <button
            className="featured-data-insights__arrow featured-data-insights__arrow--right"
            onClick={handleNext}
          >
            <FaChevronRight />
          </button>
        )}
      </div>
      <Link to={`/blog/${posts[currentIndex].slug}`}>
        <button className="featured-data-insights__read-more">Read More</button>
      </Link>
    </div>
  );
};

export default FeaturedDataInsights;