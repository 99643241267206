import React from 'react';
import './button.css';

/**
 * Button Component
 * @param {Object} props
 * @param {ReactNode} props.children - Button content
 * @param {string} props.variant - Button style variant (1, 2, etc.)
 * @param {string} props.size - Button size (s, m, l)
 * @param {string} props.className - Additional CSS classes
 * @param {boolean} props.disabled - Disabled state
 * @param {function} props.onClick - Click handler
 * @returns {JSX.Element}
 */
const Button = ({
  children,
  variant = '1',
  size = 'm',
  className = '',
  disabled = false,
  onClick,
  ...props
}) => {
  return (
    <button
      className={`btn btn--${variant} size--${size} ${disabled ? 'btn--disabled' : ''} ${className}`}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;