import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkAuthStatus, get_current_user } from '../actions/auth';
import { signOut, getCurrentUser } from '@aws-amplify/auth';
import { createBackendUser } from '../actions/auth';
import LoadingSpinner from './LoadingSpinner';

const ProtectedRoute = ({ component: Component }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const checkUserAccess = async () => {
            try {
                const authResult = await checkAuthStatus();

                if (!authResult.isAuthenticated || !authResult.session) {
                    navigate('/login');
                    return;
                }

                try {
                    const backendUser = await get_current_user();
                    if (!backendUser || !backendUser.id) {
                        // If there's no backend user, just redirect to login
                        // Don't try to create one here since App.js handles that
                        await signOut();
                        alert('User profile not found. Please try signing in again.');
                        navigate('/login');
                        return;
                    }

                    setIsAuthorized(true);

                } catch (error) {
                    if (error.name === 'UserNotConfirmedException') {
                        navigate('/verify', {
                            state: {
                                fromDashboard: true,
                                email: authResult.session.tokens.idToken.payload.email
                            }
                        });
                    } else {
                        await signOut();
                        alert('Something went wrong. Please try signing in again.');
                        navigate('/login');
                    }
                }
            } catch (error) {
                console.error('Error checking user access:', error);
                navigate('/login');
            } finally {
                setIsLoading(false);
            }
        };

        checkUserAccess();
    }, [navigate]);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return isAuthorized ? <Component /> : null;
};

export default ProtectedRoute;