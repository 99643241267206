// Signup.js

import React, { useState } from 'react';
import './Signup.css';
import { connect } from 'react-redux';
import { createCognitoUser, checkUserExists, handleGoogleSignIn } from '../actions/auth';
import { useNavigate, Link } from 'react-router-dom';
import zxcvbn from 'zxcvbn';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import logoImage from '../assets/RA-logo-2-beta.svg';
import Navbar from '../components/Navbar';
import googleLogo from '../assets/google logo 2.svg';
import axios from 'axios';
import signupBackgroundImage from '../assets/sign-up-page-building.svg';
import LoadingSpinner from '../components/LoadingSpinner'; // Import the LoadingSpinner component
import Button from '../components/ui/Button';
import { signInWithRedirect } from '@aws-amplify/auth';


const Signup = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        re_password: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const { first_name, last_name, email, password, re_password } = formData;

    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordStrengthText, setPasswordStrengthText] = useState('');
    const [subscribeToMailingList, setSubscribeToMailingList] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showRePassword, setShowRePassword] = useState(false);
    const [emailError, setEmailError] = useState(null);
    const [firstNameError, setFirstNameError] = useState(null);
    const [lastNameError, setLastNameError] = useState(null);

    const navigate = useNavigate();

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (e.target.name === 'password') {
            const score = zxcvbn(e.target.value).score;
            setPasswordStrength(score);

            let strengthText = '';
            let strengthColor = '';

            switch (score) {
                case 0:
                    strengthText = 'Very Weak';
                    strengthColor = 'red';
                    break;
                case 1:
                    strengthText = 'Weak';
                    strengthColor = 'orange';
                    break;
                case 2:
                    strengthText = 'Moderate';
                    strengthColor = '#e6b400';
                    break;
                case 3:
                    strengthText = 'Strong';
                    strengthColor = 'yellowgreen';
                    break;
                case 4:
                    strengthText = 'Very Strong';
                    strengthColor = 'green';
                    break;
                default:
                    strengthText = '';
                    strengthColor = 'transparent';
            }

            setPasswordStrengthText(
                <span style={{ color: '#1352e2', fontStyle: 'italic' }}>
                    Password Strength: <span style={{ color: strengthColor }}>{strengthText}</span>
                </span>
            );
        }

        setEmailError(null); // Clear email error when input changes
        setFirstNameError(null); // Clear first name error when input changes
        setLastNameError(null); // Clear last name error when input changes
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        let isValid = true;

        // Validate first name
        if (/\d/.test(first_name)) {
            setFirstNameError("First name cannot contain numbers");
            isValid = false;
        } else {
            setFirstNameError(null);
        }

        // Validate last name
        if (/\d/.test(last_name)) {
            setLastNameError("Last name cannot contain numbers");
            isValid = false;
        } else {
            setLastNameError(null);
        }

        if (password !== re_password) {
            alert("Passwords don't match");
            setIsLoading(false);
            return;
        }

        if (password.length < 8) {
            alert("Password is too short. It should be at least 8 characters long.");
            setIsLoading(false);
            return;
        }

        if (!isValid) {
            setIsLoading(false);
            return;
        }

        try {
            // Check if user already exists
            const userCheck = await checkUserExists(email);

            console.log('User check:', userCheck);
            if (userCheck.exists) {
                if (userCheck.provider === 'google') {
                    setEmailError('An account with this email already exists using Google Sign-In. Please use Google to sign in.');
                } else {
                    setEmailError('An account with this email already exists');
                }
                setIsLoading(false);
                return;
            }

            const result = await createCognitoUser(email, password, first_name, last_name);

            console.log('User creation successful:', result);

            // In your Signup.js component, update the navigation:
            navigate('/verify', {
                state: {
                    email,
                    password, // Add this
                    userData: {
                        userId: result.userId,
                        firstName: first_name,
                        lastName: last_name
                    }
                }
            });
        } catch (error) {
            console.error('Error creating user:', error);
            if (error.code === 'UsernameExistsException') {
                setEmailError('An account with this email already exists');
            } else {
                alert(error.message);
            }
            setIsLoading(false);
        } finally {
            setIsLoading(false); // End loading
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleRePasswordVisibility = () => {
        setShowRePassword(!showRePassword);
    };

    const getPasswordStrengthColor = () => {
        switch (passwordStrength) {
            case 0:
                return 'red';
            case 1:
                return 'orange';
            case 2:
                return '#e6b400';
            case 3:
                return 'yellowgreen';
            case 4:
                return 'green';
            default:
                return 'transparent';
        }
    };

    const continueWithGoogle = async () => {
        setIsLoading(true);
        try {
            await handleGoogleSignIn(); // Use our helper function from auth.js
        } catch (error) {
            console.error('Error initiating Google sign-in:', error);
            alert(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div className="signup-page">
            <Navbar isSignupPage={true} />
            <div className="login-page">
                <img
                    src={signupBackgroundImage}
                    alt="Signup Background"
                    className="signup-background-image"
                />
                <div className="login-card">
                    <form onSubmit={e => onSubmit(e)}>
                        <img src={logoImage} alt="Logo" className="login-logo" />
                        <div className="input-group">
                            <input
                                type="text"
                                name="first_name"
                                placeholder="First Name*"
                                value={first_name}
                                onChange={e => onChange(e)}
                                required
                            />
                            {firstNameError && <div className="error-message">{firstNameError}</div>}
                        </div>
                        <div className="input-group">
                            <input
                                type="text"
                                name="last_name"
                                placeholder="Last Name*"
                                value={last_name}
                                onChange={e => onChange(e)}
                                required
                            />
                            {lastNameError && <div className="error-message">{lastNameError}</div>}
                        </div>
                        <div className="input-group">
                            <input
                                type="email"
                                name="email"
                                placeholder="Email*"
                                value={email}
                                onChange={e => onChange(e)}
                                required
                            />
                            {emailError && <div className="error-message">{emailError}</div>}
                        </div>
                        <div className="input-group">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                placeholder="Password*"
                                value={password}
                                onChange={e => onChange(e)}
                                minLength="8"
                                required
                            />
                            <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                        <div className="password-strength-bar">
                            <div
                                className="password-strength-bar-fill"
                                style={{
                                    width: `${passwordStrength * 25}%`,
                                    backgroundColor: getPasswordStrengthColor(),
                                }}
                            ></div>
                        </div>
                        <div className="password-strength-text">{passwordStrengthText}</div>
                        <div className="input-group">
                            <input
                                type={showRePassword ? 'text' : 'password'}
                                name="re_password"
                                placeholder="Confirm Password*"
                                value={re_password}
                                onChange={e => onChange(e)}
                                minLength="8"
                                required
                            />
                            <span className="password-toggle-icon" onClick={toggleRePasswordVisibility}>
                                {showRePassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                        {/* <div className="mailing-list-option">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={subscribeToMailingList}
                                    onChange={() => setSubscribeToMailingList(!subscribeToMailingList)}
                                />
                                Add to mailing list
                            </label>
                        </div> */}

                        <Button
                            type="submit"
                            variant="2"
                            size="l"
                            className="signup-button"
                            style={{ width: '100%', margin: 0 }}
                        >
                            Register
                        </Button>
                        <div className="divider-container">
                            <div className="divider-line"></div>
                            <span className="divider-text">or</span>
                            <div className="divider-line"></div>
                        </div>
                        <Button
                            type="button"
                            variant="2"
                            size="l"
                            onClick={continueWithGoogle}
                            className="social-login-button"
                            style={{ width: '100%', margin: 0 }}
                        >
                            <div className="social-login-content">
                                <img
                                    src={googleLogo}
                                    alt="Google"
                                    className="social-login-icon"
                                />
                                <span>Continue with Google</span>
                            </div>
                        </Button>
                    </form>
                    <div className="register-footer">
                        <span>Already have an account? - </span>
                        <Link to="/login">Login</Link>
                    </div>
                    <div className="divider"></div>
                </div>
            </div>
        </div>
    );
};

export default Signup;