// Footer.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { checkAuthStatus } from '../actions/auth';
import { signOut } from '@aws-amplify/auth';
import './Footer.css';
import blueLogo from '../assets/SVG-realanalytica-primary-logo-blue.svg';
import blueBetaLogo from '../assets/RA-logo-1-beta.svg';

const Footer = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // Check auth status whenever route changes
    useEffect(() => {
        const checkAuth = async () => {
            try {
                const status = await checkAuthStatus();
                setIsAuthenticated(status.isAuthenticated);
            } catch (error) {
                setIsAuthenticated(false);
            }
        };

        checkAuth();
    }, [location.pathname]); // Re-check when route changes

    const logoutUser = async () => {
        try {
            await signOut();
            setIsAuthenticated(false);
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const generalLinks = (
        <>
            <Link
                to="/"
                title="RealAnalytica Home - Your Analytics Dashboard"
                aria-label="Return to RealAnalytica homepage"
            >
                Home
            </Link>
            <Link
                to="/blog"
                title="RealAnalytica Blog - Analytics Insights and Updates"
                aria-label="Visit our blog for analytics insights"
            >
                Blog
            </Link>
            <Link
                to="/contact"
                title="Contact RealAnalytica - Get Support and Information"
                aria-label="Contact our support team"
            >
                Contact
            </Link>
            <Link
                to="/privacy-policy"
                title="RealAnalytica Privacy Policy - Data Protection Information"
                aria-label="Read our privacy policy"
            >
                Privacy Policy
            </Link>
        </>
    );

    const authLinks = (
        <>
            <Link
                to="/dashboard"
                title="RealAnalytica Dashboard - Your Analytics Overview"
                aria-label="Access your analytics dashboard"
            >
                Dashboard
            </Link>
            <Link
                to="/account"
                title="RealAnalytica Account Management"
                aria-label="Manage your account settings"
            >
                Account
            </Link>
            <Link
                to="/"
                onClick={logoutUser}
                title="Sign Out of RealAnalytica"
                aria-label="Sign out of your account"
            >
                Logout
            </Link>
        </>
    );

    const guestLinks = (
        <>
            <Link
                to="/signup"
                title="Sign Up for RealAnalytica Analytics Platform"
                aria-label="Create a new analytics account"
            >
                Sign Up
            </Link>
            <Link
                to="/login"
                title="Sign In to RealAnalytica Dashboard"
                aria-label="Sign in to your account"
            >
                Login
            </Link>
        </>
    );

    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-links">
                    <div className="footer-section">
                        {generalLinks}
                    </div>
                    <div className="footer-section">
                        {isAuthenticated ? authLinks : guestLinks}
                    </div>
                </div>
            </div>
            <div className="footer-logo">
                <img src={blueBetaLogo} alt="RealAnalytica Logo" />
            </div>
        </footer>
    );
};

export default Footer;