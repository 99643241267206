import React, { useState, useMemo, useEffect, useContext } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./MarketOverlays.css";
import blueIcon from "../assets/SVG-realanalytica-icon-blue.svg";
import { useNavigate } from "react-router-dom";
import { FaSearch, FaTimes } from "react-icons/fa";
import MapPreview from "./MapPreview.js";

import algoIcon from "../assets/algo icon-01 1.svg";
import recentHomeSalesIcon from "../assets/home-general-icon.svg";
import schoolIcon from "../assets/schools-icon.svg";
import populationIcon from "../assets/community-icon.svg";
import crimeIcon from "../assets/crime-icon-01 1.svg";
import incomeIcon from "../assets/dollarsign-icon.svg";
import dynamicNeighborhoodComparisonIcon from "../assets/home-general-icon.svg";
import tabularNeighborhoodComparisonIcon from "../assets/home-general-icon.svg";
import savedPresetsIcon from "../assets/saved presets.svg";
import locationMetricsIcon from "../assets/locationMetricsIcon.svg";
import MarketModePage from "./MarketModePage.js";
import StatisticsTab from "./StatisticsTab";
import AutoMarketPage from "./AutoMarketPage";
import ResearchPage from "./ResearchPage";
import Fuse from "fuse.js";
import us_states from "../assets/us_states.json";
import ri_cities from "../assets/ri/Municipalities_1997_4186004077767655023.json";
import providence_neighborhoods from "../assets/ri/Providence Neighborhood Boundaries.json";
import { fetchRhodeIslandLocations } from "../utils/locationData.js";
import Tutorial from "./Tutorial.js";
import TrashModal from "./TrashModal";
import trashIcon from "../assets/trashIcon.svg";
import { MarketModeContext } from "../contexts/MarketModeContext.js";
import blueBetaIcon from "../assets/RA-logo-2-beta.svg";

const MOCK_LOCATIONS = [
  {
    id: "ri_pvd_001",
    name: "Fox Point",
    fullName: "Fox Point, Providence, Rhode Island",
    type: "neighborhood",
  },
  {
    id: "ri_pvd_002",
    name: "Providence",
    fullName: "Providence, Rhode Island",
    type: "city",
  },
];

const MarketOverlays = ({ mode, setMode, selectedFeatures }) => {
  mapboxgl.accessToken =
    "pk.eyJ1IjoiYWlkZW5mNSIsImEiOiJjbHR5cTN3ZTYwbDgwMmpvMGI1eXZrNHY2In0.sup4GHkCck0eLVun_FyZnQ";
  const navigate = useNavigate();
  const [localSelectedTab, setLocalSelectedTab] = useState(null);
  const [isStatisticsTabOpen, setIsStatisticsTabOpen] = useState(true);
  const [allLocations, setAllLocations] = useState([]);
  const [isLoadingLocations, setIsLoadingLocations] = useState(true);
  const [locationError, setLocationError] = useState(null);
  const [isTrashModalOpen, setIsTrashModalOpen] = useState(false);
  const { removeAllContentBoxes } = useContext(MarketModeContext);

  useEffect(() => {
    const loadLocations = async () => {
      setIsLoadingLocations(true);
      setLocationError(null);
      try {
        const locations = await fetchRhodeIslandLocations();
        if (locations && locations.length > 0) {
          setAllLocations(locations);
        } else {
          console.log("Using mock locations as fallback");
          setLocationError("Unable to load locations");
          setAllLocations(MOCK_LOCATIONS);
        }
      } catch (error) {
        console.error("Error fetching Rhode Island locations:", error);
        setLocationError("Unable to load locations");
        setAllLocations(MOCK_LOCATIONS);
      } finally {
        setIsLoadingLocations(false);
      }
    };

    loadLocations();
  }, []);

  // Persistent state for each mode
  const [simpleMode, setSimpleMode] = useState({
    searchQuery1: "",
    searchQuery2: "",
    selectedLocation1: null,
    selectedLocation2: null,
    showSecondSearchBox: false,
    selectedCategories: [],
    selectedDateRanges: {},
    isGenerateButtonEnabled: false,
    showDataCategories: false,
    showDataRangeSelection: false,
    showMinusButton: false,
    isDataCategoriesExpanded: false, // New state variable
  });

  const setters = useMemo(
    () => ({
      setSearchQuery1: (query) =>
        setSimpleMode((prev) => ({ ...prev, searchQuery1: query })),
      setSearchQuery2: (query) =>
        setSimpleMode((prev) => ({ ...prev, searchQuery2: query })),
      setSelectedLocation1: (location) =>
        setSimpleMode((prev) => ({ ...prev, selectedLocation1: location })),
      setSelectedLocation2: (location) =>
        setSimpleMode((prev) => ({ ...prev, selectedLocation2: location })),
      setShowSecondSearchBox: (show) =>
        setSimpleMode((prev) => ({ ...prev, showSecondSearchBox: show })),
      setSelectedCategories: (categories) =>
        setSimpleMode((prev) => ({ ...prev, selectedCategories: categories })),
      setSelectedDateRanges: (ranges) =>
        setSimpleMode((prev) => ({ ...prev, selectedDateRanges: ranges })),
      setIsGenerateButtonEnabled: (enabled) =>
        setSimpleMode((prev) => ({
          ...prev,
          isGenerateButtonEnabled: enabled,
        })),
      setShowDataCategories: (show) =>
        setSimpleMode((prev) => ({ ...prev, showDataCategories: show })),
      setShowDataRangeSelection: (show) =>
        setSimpleMode((prev) => ({ ...prev, showDataRangeSelection: show })),
      setIsDataCategoriesExpanded: (isExpanded) =>
        setSimpleMode((prev) => ({
          ...prev,
          isDataCategoriesExpanded: isExpanded,
        })),
      setShowMinusButton: (show) =>
        setSimpleMode((prev) => ({ ...prev, showMinusButton: show })),
    }),
    []
  );

  // Memoize MapPreview components
  const memoizedMapPreview1 = useMemo(() => {
    return simpleMode.selectedLocation1 ? (
      <MapPreview
        location={simpleMode.selectedLocation1}
        allLocations={allLocations} // Pass all locations for fallback
      />
    ) : null;
  }, [simpleMode.selectedLocation1, allLocations]);

  const memoizedMapPreview2 = useMemo(() => {
    return simpleMode.selectedLocation2 ? (
      <MapPreview
        location={simpleMode.selectedLocation2}
        allLocations={allLocations} // Pass all locations for fallback
      />
    ) : null;
  }, [simpleMode.selectedLocation2, allLocations]);

  const [customMode, setCustomMode] = useState({
    searchQuery: "",
    selectedSearchResult: null,
    clickedFeature: null,
    currentSelectionString: "",
  });

  const [researchMode, setResearchMode] = useState({
    selectedCity: null,
    selectedNeighborhoods: [],
    startDate: (() => {
      const date = new Date();
      date.setMonth(date.getMonth() - 3);
      return date;
    })(),
    endDate: new Date(),
    activeFilter: "3M",
    globalSelectedDataType: null,
    selectedItem: null,
  });

  const handleTabClick = (tab) => {
    console.log("🔄 Tab Click Handler:", {
      newTab: tab,
      previousTab: localSelectedTab,
    });

    setLocalSelectedTab(tab);
    setIsStatisticsTabOpen(true);
  };

  const handleClose = () => {
    setIsStatisticsTabOpen(false);
    setLocalSelectedTab(null);
  };

  const capitalizeWords = (str) => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleSearch = (query) => {
    setCustomMode((prevState) => ({ ...prevState, searchQuery: query }));

    if (query.trim() !== "") {
      const fuse = new Fuse(allLocations, {
        keys: ["name", "fullName"],
        includeScore: true,
        threshold: 0.3,
      });

      const results = fuse.search(query);
      const topFourResults = results.slice(0, 4).map((result) => result.item);
      setCustomMode((prevState) => ({
        ...prevState,
        searchResults: topFourResults,
        showSearchResults: true,
      }));
    } else {
      setCustomMode((prevState) => ({
        ...prevState,
        searchResults: [],
        showSearchResults: false,
      }));
    }
  };

  const handleSearchResultClick = (result) => {
    console.log("🔍 Search Result Clicked:", {
      result,
      currentTab: localSelectedTab,
      isStatisticsTabOpen,
    });

    const formattedResult = formatSearchResult(result);
    const featureWithGeoId = {
      ...result,
      geoId: result.id.trim(),
    };

    // First update the customMode state
    setCustomMode((prevState) => ({
      ...prevState,
      selectedSearchResult: result,
      searchQuery: formattedResult,
      showSearchResults: false,
      currentSelectionString: formattedResult,
      clickedFeature: featureWithGeoId,
    }));

    // Then handle tab selection
    if (!localSelectedTab) {
      console.log("🎯 No tab selected, automatically selecting Home Sales");
      handleTabClick("recentHomeSales");
    } else {
      console.log("📌 Tab already selected:", localSelectedTab);
    }
  };

  const formatSearchResult = (result) => {
    if (result.type === "neighborhood") {
      const cityName = result.fullName.split(",")[1].trim();
      return `${result.name}, ${cityName}, Rhode Island`;
    } else if (result.type === "city") {
      return `${result.name}, Rhode Island`;
    }
    return result.name;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (customMode.searchResults && customMode.searchResults.length > 0) {
      const closestMatch = customMode.searchResults[0];
      handleSearchResultClick(closestMatch);
    }
  };

  const handleTrashClick = () => {
    setIsTrashModalOpen(true);
  };

  const handleConfirmClear = () => {
    // Add your clear market sheet logic here
    setIsTrashModalOpen(false);
    removeAllContentBoxes();
  };

  const handleModeChange = (selectedMode) => {
    // Update URL query parameter without page refresh
    const url = new URL(window.location.href);
    url.searchParams.set('mode', selectedMode);
    window.history.pushState({}, '', url);

    // Save current mode's state
    switch (mode) {
      case "simple":
        setSimpleMode((prevState) => ({
          ...prevState,
          // Add any additional state you want to preserve
        }));
        break;
      case "custom":
        setCustomMode((prevState) => ({
          ...prevState,
          // Add any additional state you want to preserve
        }));
        break;
      case "research":
        setResearchMode((prevState) => ({
          ...prevState,
          // Add any additional state you want to preserve
        }));
        break;
      default:
        // Handle any unexpected mode
        console.warn(`Unexpected mode: ${mode}`);
    }

    if (selectedMode === "simple") {
      setSimpleMode((prevState) => ({
        ...prevState,
        // Add any additional state you want to preserve
      }));
    }

    // Switch to new mode
    setMode(selectedMode);

    // Restore new mode's state (if needed)
    switch (selectedMode) {
      case "simple":
        // Restore simple mode state if needed
        break;
      case "custom":
        // Restore custom mode state if needed
        break;
      case "research":
        // Restore research mode state if needed
        break;
    }

    const modeButtons = document.querySelector(".mode-buttons");
    modeButtons.className = `mode-buttons ${selectedMode}-active`;
  };
  const ModeSelectorButton = ({ currentMode, onModeChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const isMobile = window.innerWidth <= 768;

    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    const selectMode = (mode) => {
      if (isMobile && (mode === "custom" || mode === "research")) {
        return; // Disabled on mobile
      }

      // Update URL query parameter without page refresh
      const url = new URL(window.location.href);
      url.searchParams.set('mode', mode);
      window.history.pushState({}, '', url);

      onModeChange(mode);
      setIsOpen(false);
    };

    const getModeDisplayName = (mode) => {
      switch (mode) {
        case "simple":
          return "SnapSheets™";
        case "custom":
          return "CustomCanvas™";
        case "research":
          return "DeepDive™";
        default:
          return mode;
      }
    };

    return (
      <>
        <div className="mode-selector-button">
          <button onClick={toggleDropdown}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="3" y1="12" x2="21" y2="12" />
              <line x1="3" y1="6" x2="21" y2="6" />
              <line x1="3" y1="18" x2="21" y2="18" />
            </svg>
            {getModeDisplayName(currentMode)}
          </button>
        </div>

        <div
          className={`mode-selector-dropdown ${isOpen ? "mobile-open" : ""}`}
        >
          <div
            className={`mode-option ${currentMode === "simple" ? "active" : ""
              }`}
            onClick={() => selectMode("simple")}
          >
            SnapSheets™
          </div>
          <div
            className={`mode-option disabled ${currentMode === "custom" ? "active" : ""
              }`}
            onClick={() => selectMode("custom")}
          >
            CustomCanvas™
          </div>
          <div
            className={`mode-option disabled ${currentMode === "research" ? "active" : ""
              }`}
            onClick={() => selectMode("research")}
          >
            DeepDive™
          </div>
        </div>

        {isOpen && (
          <div
            className="mobile-menu-overlay active"
            onClick={() => setIsOpen(false)}
          />
        )}
      </>
    );
  };

  const handleExit = () => {
    navigate("/account?tab=Dashboard");
  };

  return (
    <div>
      <nav
        className={`navbar dashboard-page ${mode === "simple" ? "simple-mode" : ""
          }`}
      >
        <div className="navbar-container">
          <ModeSelectorButton
            currentMode={mode}
            onModeChange={handleModeChange}
          />
          <div className="navbar-logo">
            <img
              src={blueBetaIcon}
              alt="RealAnalytica Logo"
              className="logoIcon"
              onClick={handleExit}
            />
          </div>
          {mode === "custom" && (
            <div className="marketoverlays-search-bar">
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder={
                    isLoadingLocations
                      ? "Fetching data, please wait a moment..."
                      : locationError
                        ? "Data unavailable. Please try again later."
                        : "Search: state, city, or neighborhood"
                  }
                  value={customMode.searchQuery}
                  onChange={(e) => handleSearch(e.target.value)}
                  disabled={isLoadingLocations || locationError}
                  className="marketoverlays-search-input"
                />
                <button
                  type="submit"
                  disabled={isLoadingLocations || locationError}
                  className="marketoverlays-search-button"
                >
                  <FaSearch />
                </button>
              </form>
              {locationError && (
                <div className="marketoverlays-search-error">
                  <p>Unable to load locations. Please try again later.</p>
                </div>
              )}
              {customMode.showSearchResults &&
                !isLoadingLocations &&
                !locationError && (
                  <div className="marketoverlays-search-results">
                    <h3>Search Results:</h3>
                    <ul>
                      {customMode.searchResults.map((result, index) => (
                        <li
                          key={index}
                          onClick={() => handleSearchResultClick(result)}
                        >
                          {formatSearchResult(result)}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
            </div>
          )}
          <div className="mode-buttons-container">
            <div className="mode-buttons-wrapper">
              <div className={`mode-buttons ${mode}-active`}>
                <button
                  className={mode === "simple" ? "active" : ""}
                  onClick={() => handleModeChange("simple")}
                >
                  SnapSheets™
                </button>
                <button
                  className={mode === "custom" ? "active" : ""}
                  onClick={() => handleModeChange("custom")}
                >
                  CustomCanvas™
                </button>
                <button
                  className={mode === "research" ? "active" : ""}
                  onClick={() => handleModeChange("research")}
                >
                  DeepDive™
                </button>
              </div>
              <div className="location-availability-text">
                Available Locations: Rhode Island
              </div>
            </div>
          </div>
          <div className="exit-button">
            <button onClick={handleExit}>
              <FaTimes style={{ fontSize: 18 }} />
            </button>
          </div>
        </div>
      </nav>
      {mode === "custom" && (
        <div className="main-sidebar">
          <div className="sidebar-item">
            <img
              src={algoIcon}
              alt="Algo"
              className={`algo-icon ${localSelectedTab === "algo" ? "selected" : ""
                }`}
              onClick={() => handleTabClick("algo")}
            />
            <span className="sidebar-label">Algo</span>
          </div>
          <div className="sidebar-item">
            <img
              src={recentHomeSalesIcon}
              alt="Recent Home Sales"
              className={`recent-home-sales-icon ${localSelectedTab === "recentHomeSales" ? "selected" : ""
                }`}
              onClick={() => handleTabClick("recentHomeSales")}
            />
            <span className="sidebar-label">Home Sales</span>
          </div>
          <div className="sidebar-item">
            <img
              src={locationMetricsIcon}
              alt="Location Metrics"
              className={`location-metrics-icon ${localSelectedTab === "locationMetrics" ? "selected" : ""
                }`}
              onClick={() => handleTabClick("locationMetrics")}
            />
            <span className="sidebar-label">Location</span>
          </div>
          <div className="sidebar-item">
            <img
              src={schoolIcon}
              alt="School"
              className={`school-icon ${localSelectedTab === "school" ? "selected" : ""
                }`}
              onClick={() => handleTabClick("school")}
            />
            <span className="sidebar-label">Schools</span>
          </div>
          <div className="sidebar-item">
            <img
              src={populationIcon}
              alt="Population"
              className={`population-icon ${localSelectedTab === "population" ? "selected" : ""
                }`}
              onClick={() => handleTabClick("population")}
            />
            <span className="sidebar-label">Population</span>
          </div>
          <div className="sidebar-item">
            <img
              src={crimeIcon}
              alt="Crime"
              className={`crime-icon ${localSelectedTab === "crime" ? "selected" : ""
                }`}
              onClick={() => handleTabClick("crime")}
            />
            <span className="sidebar-label">Crime</span>
          </div>
          <div className="sidebar-item">
            <img
              src={incomeIcon}
              alt="Income"
              className={`income-icon ${localSelectedTab === "income" ? "selected" : ""
                }`}
              onClick={() => handleTabClick("income")}
            />
            <span className="sidebar-label">Income</span>
          </div>
          <div className="sidebar-item">
            <img
              src={dynamicNeighborhoodComparisonIcon}
              alt="Dynamic Neighborhood Comparison"
              className={`dynamic-neighborhood-comparison-icon ${localSelectedTab === "dynamicNeighborhoodComparison"
                ? "selected"
                : ""
                }`}
              onClick={() => handleTabClick("dynamicNeighborhoodComparison")}
            />
            <span className="sidebar-label">Compare</span>
          </div>
          <div className="sidebar-item">
            <img
              src={trashIcon}
              alt="Clear Sheet"
              className="saved-presets-icon"
              onClick={handleTrashClick}
            />
            <span className="sidebar-label">Clear</span>
          </div>
        </div>
      )}
      {mode === "custom" && <div className="statistics-tab-block"></div>}
      {mode === "custom" && (
        <MarketModePage isStatisticsTabOpen={isStatisticsTabOpen} />
      )}
      {mode === "simple" && (
        <div>
          <AutoMarketPage
            {...simpleMode}
            {...setters}
            memoizedMapPreview1={memoizedMapPreview1}
            memoizedMapPreview2={memoizedMapPreview2}
            allLocations={allLocations}
          />
        </div>
      )}
      {mode === "research" && (
        <div style={{ backgroundColor: "#ECECEC", minHeight: "100vh" }}>
          <ResearchPage
            selectedCity={researchMode.selectedCity}
            setSelectedCity={(city) =>
              setResearchMode((prev) => ({ ...prev, selectedCity: city }))
            }
            selectedNeighborhoods={researchMode.selectedNeighborhoods}
            setSelectedNeighborhoods={(neighborhoods) => {
              const newNeighborhoods = Array.isArray(neighborhoods)
                ? neighborhoods
                : [];
              setResearchMode((prev) => ({
                ...prev,
                selectedNeighborhoods: newNeighborhoods,
              }));
            }}
            startDate={researchMode.startDate}
            setStartDate={(date) =>
              setResearchMode((prev) => ({ ...prev, startDate: date }))
            }
            endDate={researchMode.endDate}
            setEndDate={(date) =>
              setResearchMode((prev) => ({ ...prev, endDate: date }))
            }
            activeFilter={researchMode.activeFilter}
            setActiveFilter={(filter) =>
              setResearchMode((prev) => ({ ...prev, activeFilter: filter }))
            }
            globalSelectedDataType={researchMode.globalSelectedDataType}
            setGlobalSelectedDataType={(dataType) =>
              setResearchMode((prev) => ({
                ...prev,
                globalSelectedDataType: dataType,
              }))
            }
            selectedItem={researchMode.selectedItem}
            setSelectedItem={(item) =>
              setResearchMode((prev) => ({ ...prev, selectedItem: item }))
            }
          />
        </div>
      )}
      {mode === "custom" && (
        <StatisticsTab
          selectedTab={localSelectedTab}
          onClose={handleClose}
          clickedFeature={customMode.clickedFeature}
          selectedFeatures={selectedFeatures}
          isOpen={isStatisticsTabOpen}
          currentSelectionString={customMode.currentSelectionString}
          onTabSelect={handleTabClick}
        />
      )}
      {mode === "custom" && <Tutorial />}
      <TrashModal
        isOpen={isTrashModalOpen}
        onClose={() => setIsTrashModalOpen(false)}
        onConfirm={handleConfirmClear}
      />
    </div>
  );
};

export default MarketOverlays;
