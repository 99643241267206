import React, { useMemo, createContext, useState, useEffect } from 'react';
import { recreateContent } from '../components/contentBoxRegistry';

export const MarketModeContext = createContext();

export const MarketModeProvider = ({ children }) => {
    const [pages, setPages] = useState([{ id: 1, contentBoxes: [] }]);
    const [savedPresets, setSavedPresets] = useState([]);
    const [selectedCities, setSelectedCities] = useState(['City A']);
    const [startDate, setStartDate] = useState(new Date(2019, 0, 1));
    const [endDate, setEndDate] = useState(new Date());
    const [quickFilter, setQuickFilter] = useState('5Y');

    const calculateRowsForSubtype = (boxes) => {
        return Math.ceil(boxes.length / 2);
    };

    const calculateTotalRowsOnPage = (page) => {
        const boxesBySubtype = page.contentBoxes.reduce((acc, box) => {
            if (!acc[box.subtype]) acc[box.subtype] = [];
            acc[box.subtype].push(box);
            return acc;
        }, {});

        let totalRows = 0;
        Object.values(boxesBySubtype).forEach(subtypeBoxes => {
            totalRows += calculateRowsForSubtype(subtypeBoxes);
        });
        return totalRows;
    };

    const shouldStartNewPage = (page, newBox) => {
        if (page.contentBoxes.length === 0) return false;
    
        // Group boxes by subtype only, ignoring location
        const boxesBySubtype = page.contentBoxes.reduce((acc, box) => {
            if (!acc[box.subtype]) acc[box.subtype] = [];
            acc[box.subtype].push(box);
            return acc;
        }, {});
    
        // Calculate current total rows
        const currentTotalRows = Object.values(boxesBySubtype).reduce((total, boxes) => {
            return total + Math.ceil(boxes.length / 2);
        }, 0);
    
        // Calculate how many rows the new box would add
        const currentSubtypeBoxes = boxesBySubtype[newBox.subtype] || [];
        const currentSubtypeRows = Math.ceil(currentSubtypeBoxes.length / 2);
        const newSubtypeRows = Math.ceil((currentSubtypeBoxes.length + 1) / 2);
        const additionalRows = newSubtypeRows - currentSubtypeRows;
    
        // Check if adding this box would exceed limits
        const totalSubtypes = Object.keys(boxesBySubtype).length;
        const isNewSubtype = !boxesBySubtype[newBox.subtype];
        const wouldExceedRowLimit = currentTotalRows + additionalRows > 3;
        const wouldExceedSubtypeLimit = isNewSubtype && totalSubtypes >= 3;
    
        return wouldExceedRowLimit || wouldExceedSubtypeLimit;
    };
    
    const canFitOnPage = (page, newBox) => {
        // Group boxes by subtype only, ignoring location
        const boxesBySubtype = page.contentBoxes.reduce((acc, box) => {
            if (!acc[box.subtype]) acc[box.subtype] = [];
            acc[box.subtype].push(box);
            return acc;
        }, {});
    
        const currentSubtypeBoxes = boxesBySubtype[newBox.subtype] || [];
        const currentTotalRows = Object.values(boxesBySubtype).reduce((total, boxes) => {
            return total + Math.ceil(boxes.length / 2);
        }, 0);
    
        const currentSubtypeRows = Math.ceil(currentSubtypeBoxes.length / 2);
        const newSubtypeRows = Math.ceil((currentSubtypeBoxes.length + 1) / 2);
        const additionalRows = newSubtypeRows - currentSubtypeRows;
    
        const totalSubtypes = Object.keys(boxesBySubtype).length;
        const isNewSubtype = !boxesBySubtype[newBox.subtype];
    
        return (currentTotalRows + additionalRows <= 3) && 
               (!isNewSubtype || totalSubtypes < 3);
    };

    const addContentBox = (contentBox) => {
        setPages((prevPages) => {
            let updatedPages = [...prevPages];
            let lastPage = updatedPages[updatedPages.length - 1];

            if (shouldStartNewPage(lastPage, contentBox)) {
                const newPage = { id: lastPage.id + 1, contentBoxes: [contentBox] };
                updatedPages.push(newPage);
            } else {
                lastPage.contentBoxes.push(contentBox);
            }

            return reflowContent(updatedPages);
        });
    };

    const addMultipleContentBoxes = (contentBoxes) => {
        setPages((prevPages) => {
            let updatedPages = [...prevPages];

            contentBoxes.forEach(newBox => {
                const boxExists = updatedPages.some(page =>
                    page.contentBoxes.some(existingBox =>
                        existingBox.title === newBox.title && 
                        existingBox.subtype === newBox.subtype
                    )
                );

                if (!boxExists) {
                    let lastPage = updatedPages[updatedPages.length - 1];

                    if (shouldStartNewPage(lastPage, newBox)) {
                        const newPage = { id: lastPage.id + 1, contentBoxes: [newBox] };
                        updatedPages.push(newPage);
                    } else {
                        lastPage.contentBoxes.push(newBox);
                    }
                }
            });

            return reflowContent(updatedPages);
        });
    };

    const reflowContent = (pages) => {
        let reflowed = false;
        for (let i = 1; i < pages.length; i++) {
            const currentPage = pages[i];
            const previousPage = pages[i - 1];

            while (currentPage.contentBoxes.length > 0) {
                const firstBox = currentPage.contentBoxes[0];
                if (canFitOnPage(previousPage, firstBox)) {
                    const boxToMove = currentPage.contentBoxes.shift();
                    previousPage.contentBoxes.push(boxToMove);
                    reflowed = true;
                } else {
                    break;
                }
            }
        }

        return pages.filter((page, index) => 
            index === 0 || page.contentBoxes.length > 0
        );
    };

    const removeContentBox = (boxToRemove) => {
        setPages((prevPages) => {
            let updatedPages = prevPages.map(page => ({
                ...page,
                contentBoxes: page.contentBoxes.filter(box => box.key !== boxToRemove.key)
            }));
            return reflowContent(updatedPages);
        });
    };

    const removeAllContentBoxes = () => {
        setPages([{ id: 1, contentBoxes: [] }]);
    };

    useEffect(() => {
        const storedPresets = localStorage.getItem('savedPresets');
        if (storedPresets) {
            setSavedPresets(JSON.parse(storedPresets));
        }
    }, []);

    const savePreset = (presetName, serializablePages) => {
        const newPreset = {
            name: presetName,
            pages: serializablePages
        };
        const updatedPresets = [...savedPresets, newPreset];
        setSavedPresets(updatedPresets);
        localStorage.setItem('savedPresets', JSON.stringify(updatedPresets));
    };

    const loadPreset = (preset) => {
        const loadedPages = preset.pages.map(page => ({
            ...page,
            contentBoxes: page.contentBoxes.map(box => ({
                ...box,
                content: recreateContent(box.contentType, box.props)
            }))
        }));
        setPages(loadedPages);
    };

    const deletePreset = (presetName) => {
        const updatedPresets = savedPresets.filter(preset => preset.name !== presetName);
        setSavedPresets(updatedPresets);
        localStorage.setItem('savedPresets', JSON.stringify(updatedPresets));
    };

    const contextValue = useMemo(() => ({
        pages,
        addContentBox,
        addMultipleContentBoxes,
        removeContentBox,
        removeAllContentBoxes,
        algoState: {
            selectedCities,
            setSelectedCities,
            startDate,
            setStartDate,
            endDate,
            setEndDate,
            quickFilter,
            setQuickFilter
        },
        savedPresets,
        savePreset,
        loadPreset,
        deletePreset
    }), [pages, selectedCities, startDate, endDate, quickFilter, savedPresets]);

    return (
        <MarketModeContext.Provider value={contextValue}>
            {children}
        </MarketModeContext.Provider>
    );
};

export default MarketModeContext;