// src/pages/PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div style={{
            maxWidth: '800px',
            margin: '40px auto',
            padding: '20px',
            lineHeight: '1.6'
        }}>
            <h1>Privacy Policy</h1>
            <p>Last updated: January 12, 2025</p>

            <section>
                <h2>1. Information We Collect</h2>
                <p>We collect information that you provide directly to us, including:</p>
                <ul>
                    <li>Name and email address when you create an account</li>
                    <li>Profile information when you sign in with Google</li>
                    <li>Usage data and analytics as you use our service</li>
                </ul>
            </section>

            <section>
                <h2>2. How We Use Your Information</h2>
                <p>We use the information we collect to:</p>
                <ul>
                    <li>Provide, maintain, and improve our services</li>
                    <li>Process and complete transactions</li>
                    <li>Send you technical notices and support messages</li>
                    <li>Communicate with you about products, services, and updates</li>
                </ul>
            </section>

            <section>
                <h2>3. Information Sharing</h2>
                <p>We do not sell or rent your personal information to third parties. We may share your information only in the following circumstances:</p>
                <ul>
                    <li>With your consent</li>
                    <li>To comply with legal obligations</li>
                    <li>To protect our rights and prevent fraud</li>
                </ul>
            </section>

            <section>
                <h2>4. Security</h2>
                <p>We implement reasonable security measures to protect your personal information from unauthorized access, alteration, or destruction.</p>
            </section>

            <section>
                <h2>5. Contact Us</h2>
                <p>If you have any questions about this Privacy Policy, please contact us at:</p>
                <p>Email: aiden@realanalytica.com</p>
            </section>
        </div>
    );
};

export default PrivacyPolicy;