import React from 'react';
import './UserDashboardHomePage.css';
import userDashboardGif from '../assets/userDashboardGif.mp4';

const UserDashboardHomePage = () => {
    return (
        <div className="user-dashboard-section">
            <div className="user-dashboard-content">
                <h2>Your Market Intelligence Hub</h2>
                <video
                    className="user-dashboard-video"
                    autoPlay
                    loop
                    muted
                    playsInline
                    poster={`${userDashboardGif}?frame=1`}
                >
                    <source src={userDashboardGif} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <ul className="dashboard-features">
                    <li>Track market trends with customizable neighborhood watchlists</li>
                    <li>Access SnapSheets™, CustomCanvas™, and DeepDive™ in one place</li>
                    <li>Stay informed with curated industry insights and market updates</li>
                </ul>
            </div>
        </div>
    );
};

export default UserDashboardHomePage;